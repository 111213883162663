import axiosApiInstance from "../axios/axios";
import {
  AddMemberToRoomParams,
  CreateContractsParams,
  CreateContractsResponse,
  CreateThreadsParams,
  CreateThreadsResponse,
  DelMemberFromRoomParams,
  GetChatsByUserIdParams,
  GetChatsByUserIdResponse,
  GetContactsParams,
  GetContactsResponse,
  GetMessageByIdParams,
  GetMessageByIdResponse,
  GetMessagesResponse,
  GetMyInviteCodesResponse,
  GetOpenSeaAssetParams,
  GetOpenSeaAssetResponse,
  GetRoomInfoParams,
  GetRoomInfoResponse,
  GetRoomsParams,
  GetRoomsResponse,
  GetThreadsParams,
  GetThreadsResponse,
  UpdateWalletAddressParams,
  UpdateWalletAddressResponse,
} from "./apiInterface/chatInterface";

const NEXT_ID_HOST = "https://proof-service.next.id";

export async function getRooms(
  params: GetRoomsParams
): Promise<GetRoomsResponse> {
  return await axiosApiInstance.post("/rooms", params);
}

export async function register(params: any): Promise<any> {
  return await axiosApiInstance.post("/register", params);
}

export async function userInfo(params: any): Promise<any> {
  return await axiosApiInstance.post("/info", params);
}

export async function getMessages(params: any): Promise<GetMessagesResponse> {
  return await axiosApiInstance.post("/messages", params);
}

export async function updateWalletAddress(
  params: UpdateWalletAddressParams
): Promise<UpdateWalletAddressResponse> {
  return await axiosApiInstance.post("/wallet", params);
}

export async function getOpenSeaAssets(
  params: GetOpenSeaAssetParams
): Promise<GetOpenSeaAssetResponse> {
  return await axiosApiInstance.get("/opensea_assets", { params });
}

export async function getChatsByUserId(
  params: GetChatsByUserIdParams
): Promise<GetChatsByUserIdResponse> {
  return await axiosApiInstance.post("/my_chats", params);
}

export async function getContacts(
  params: GetContactsParams
): Promise<GetContactsResponse> {
  return await axiosApiInstance.get(`/contacts/${params.page}/${params.size}`);
}

export async function getFollowers(
  params: GetContactsParams
): Promise<GetContactsResponse> {
  return await axiosApiInstance.post('/users/followers', params);
}

export async function createThreads(
  params: CreateThreadsParams
): Promise<CreateThreadsResponse> {
  return await axiosApiInstance.post("/threads", params);
}

export async function getThreads(
  params: GetThreadsParams
): Promise<GetThreadsResponse> {
  let url = `/threads/${params.room_id}/${params.page}/${params.size}`;
  if (params.belong_to_thread_id) {
    url = `/threads/${params.room_id}/${params.page}/${params.size}?belong_to_thread_id=${params.belong_to_thread_id}&page=${params.page}&size=${params.size}`;
  }
  return await axiosApiInstance.get(url);
}

export async function getRoomInfo(
  params: GetRoomInfoParams
): Promise<GetRoomInfoResponse> {
  return await axiosApiInstance.get(`/rooms/${params.room_id}`);
}

export async function addMemberToRoom(
  params: AddMemberToRoomParams
): Promise<GetRoomInfoResponse> {
  return await axiosApiInstance.post(`/rooms/${params.room_id}/members`, {
    target_user_ids: params.target_user_ids,
  });
}

export async function delMemberFromRoom(
  params: DelMemberFromRoomParams
): Promise<GetRoomInfoResponse> {
  return await axiosApiInstance.delete(
    `/rooms/${params.room_id}/members/${params.member_id}`
  );
}

export async function getMessageById(
  params: GetMessageByIdParams
): Promise<GetMessageByIdResponse> {
  return await axiosApiInstance.get(`/messages/${params.msg_id}`);
}

export async function getMyInviteCodes(): Promise<GetMyInviteCodesResponse> {
  return await axiosApiInstance.get("/my_invite_codes");
}

export async function createNFTContract(
  params: CreateContractsParams
): Promise<CreateContractsResponse> {
  return await axiosApiInstance.post("/contracts", params);
}

export async function getNFTContractsById(
  id: string
): Promise<CreateContractsResponse> {
  return await axiosApiInstance.get(`/contracts/${id}`);
}

export async function updateContractKey(params: {
  contract_id: string;
  key: string;
}): Promise<CreateContractsResponse> {
  return await axiosApiInstance.post(`/contracts`, params);
}

export async function getContractsByUserId(params: {
  page: number;
  size: number;
  user_type: string;
}): Promise<CreateContractsResponse> {
  return await axiosApiInstance.post(`/contracts/mine`, params);
}

export async function getMyRooms(): Promise<any> {
  return await axiosApiInstance.get("/my_rooms");
}

export async function getSudoSwap(params: {
  tradeCode: string;
}): Promise<{ code: number; data: any; nsg: string }> {
  return await axiosApiInstance.get(`/sudoswap/${params.tradeCode}`);
}

export async function getNFTItemInfo(params: {
  token_id: string;
  asset_contract_address: string;
}): Promise<any> {
  return await axiosApiInstance.get(
    `https://api.opensea.io/api/v1/asset/${params.asset_contract_address}/${params.token_id}/`
  );
}

export async function sigToPubkey(params: {
  msg: string;
  sig: string;
}): Promise<any> {
  return await axiosApiInstance.post("/sig2pubkey", params);
}

export async function getOpenseaAddressByUsername(
  username: string
): Promise<any> {
  return await axiosApiInstance.post("/opensea_user_info", {
    opensea_user_id: username,
  });
}

export async function getNextIdUserInfo(params: {
  platform: string;
  username: string;
}): Promise<any> {
  return await axiosApiInstance.get(
    `https://proof-service.next.id/v1/proof?platform=${params.platform}&identity=${params.username}`
  );
}

export async function getNextIdSignPayload(params: {
  action: string;
  platform: string;
  identity: string;
  public_key: string;
}): Promise<{
  created_at: string;
  post_content: any;
  sign_payload: string;
  uuid: string;
}> {
  return await axiosApiInstance.post(
    `${NEXT_ID_HOST}/v1/proof/payload`,
    params
  );
}

export async function createNextIdUser(params: {
  action: string;
  platform: string;
  identity: string;
  proof_location: string;
  public_key: string;
  extra?: any;
  uuid: string;
  created_at: string;
}): Promise<any> {
  return axiosApiInstance.post(`${NEXT_ID_HOST}/v1/proof`, params);
}

export async function getTwitterUserInfo(
  twitterUserName: string
): Promise<any> {
  return await axiosApiInstance.post("/twitter_user_info", {
    twitter_user_name: twitterUserName,
  });
}

export async function pushNotification(params: {
  version: number;
  sender_id: string;
  to: string[];
  notification_type: string;
  notification_payload: any;
}): Promise<any> {
  return await axiosApiInstance.post("/notifications", params);
}

export async function getNotificationList(pageData: {
  page: number;
  size: number;
}) {
  return await axiosApiInstance.get(
    `/notifications/recv/${pageData.page}/${pageData.size}`
  );
}

export async function readNotifications(noIds: string[]) {
  return await axiosApiInstance.patch(`/notifications`, {
    notification_ids: noIds
  });
}

// 获取我推送的消息
export async function getPushNotificationList(pageData: {
  page: number;
  size: number;
}) {
  return await axiosApiInstance.get(
    `/notifications/${pageData.page}/${pageData.size}`
  );
}

// 获取用户nft列表
export async function getUserNFTList(params: {
  user_id: string;
  wallet_address: string;
  page: number;
  size: number;
}) {
  return await axiosApiInstance.post(`/users/${params.user_id}/nfts`, {
    wallet_address: params.wallet_address,
    page: params.page,
    size: params.size,
  });
}



// 获取.bit
export async function getUserDotBits(params: {
  user_id: string;
  wallet_address: string;
}) {
  return await axiosApiInstance.post(`/users/${params.user_id}/dotbits`, {
    wallet_address: params.wallet_address,
  });
}

// 获取nametag
export async function getUserNameTag(params: {
  user_id: string;
  wallet_address: string;
}) {
  return await axiosApiInstance.post(`/users/${params.user_id}/nametags `, {
    wallet_address: params.wallet_address,
  });
}

// 获取账号
export async function getSelfAccountByPage(params: {
  accountType: string;
  page: number,
  size: number
}) {
  return await axiosApiInstance.get(`/users/accounts/${params.accountType}/${params.page}/${params.size}`);
}

// 获取他人账号
export async function getUserAccountByPage(params: {
  accountType: string;
  page: number,
  size: number,
  userId: string
}) {
  return await axiosApiInstance.get(`/users/${params.userId}/accounts/${params.accountType}/${params.page}/${params.size}`);
}