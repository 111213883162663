import { action, makeAutoObservable, observable } from "mobx";
import * as ChatApi from "../../api/chat";
import {
  getRoomNameAndAvatarByRoom,
  getUserInfoByJWT,
  getUserInfoByMap,
} from "../../../constant/utils";
import { MESSAGE_TYPE_ENUM, PLATFORM_ENUM } from "../../../constant/enum";
import { getNotificationList } from "../../api/chat";

export default class HomeStore {
  // @observable auth:AuthState = defaultValue;
  @observable showLoading: boolean = false;
  @observable showErrorAlert: boolean = false;
  @observable errorMessage: string = "something is wrong";
  @observable contacts: any[] = [];
  @observable userFollowers: any[] = [];
  @observable chats: any[] = [];
  @observable currentSearchChatsLength = 0;
  @observable notificationCurrentSearchLength = 0;
  @observable notificationMessages: any[] = [];
  @observable unReadNotificationCount: number = 0;

  @action addUnReadNotificationCount() {
    ++this.unReadNotificationCount;
  }

  @action
  async getNotificationList(page: { page: number; size: number }) {
    let oriData = [...this.notificationMessages];
    if (page.page === 1) oriData = [];
    const data = await getNotificationList(page);
    if (data.data) {
      this.notificationCurrentSearchLength = data.data.length;
      let finalData = [];
      for (let i = 0; i < data.data.length; i++) {
        let currentItem = data.data[i];
        if (oriData.find((item) => item.id === currentItem.id)) continue;
        let userId = currentItem.sender_id;
        const userInfo = await getUserInfoByMap(userId, this.contacts);
        finalData.push({
          ...currentItem,
          sender_info: userInfo,
        });
      }
      finalData = finalData.reverse();

      this. notificationMessages = [...finalData, ...oriData]
      // if (page.page === 1) {
      //   this.notificationMessages = finalData;
      // } else {
      //   this.notificationMessages = finalData.concat(this.notificationMessages);
      // }
    } else {
      this.notificationCurrentSearchLength = 0;
    }
  }

  @action
  async readNotificationMessage(noIds: string[]) {
    if (noIds.length === 0) return
    await ChatApi.readNotifications(noIds);
    this.unReadNotificationCount = 0
    // 修改某条数据的未读情况
    this.notificationMessages = this.notificationMessages.map((item) => {
      if (noIds.includes(item.id)) {
        item.has_been_read = true;
      }
      return item;
    });
  }

  @action
  async setNotificationMessages(data: any) {
    this.notificationMessages = data;
  }

  constructor() {
    makeAutoObservable(this); //even though this isn't required in some examples, this seems key line to making mobx work
  }

  @action setShowLoading(data: boolean) {
    this.showLoading = data;
  }

  @action setShowErrorAlert(data: boolean) {
    this.showErrorAlert = data;
  }

  @action setErrorMessage(data: string) {
    this.errorMessage = data;
  }

  @action
  async getContacts(page: number = 1) {
    const contactsRes = await ChatApi.getContacts({
      page: page,
      size: 999,
    });
    if (contactsRes.code !== 0) {
      this.showLoading = false;
      this.errorMessage = "get contracts error";
      this.showErrorAlert = true;
      return;
    }
    if (contactsRes.data && contactsRes.data.length > 0) {
      this.contacts = contactsRes.data;
    } else {
      this.contacts = [];
    }
  }
  @action
  async getUserFollowers(page: number = 1) {
    const followersRes = await ChatApi.getFollowers({
      page: page,
      size: 999,
    });
    if (followersRes.code !== 0) {
      this.showLoading = false;
      this.errorMessage = "get contracts error";
      this.showErrorAlert = true;
      return;
    }
    if (followersRes.data && followersRes.data.length > 0) {
      this.userFollowers = followersRes.data;
    } else {
      this.userFollowers = [];
    }
  }

  @action
  async getChats(platform: PLATFORM_ENUM, page: number = 1, size: number = 20) {
    let oriChatData = [...this.chats];
    if (page === 1) {
      oriChatData = [];
    }
    let userInfo = getUserInfoByJWT();
    const chatRes = await ChatApi.getChatsByUserId({
      user_id: userInfo.user_id,
      page,
      size,
    });
    if (chatRes.code !== 0) {
      if (this.showLoading) this.showLoading = false;
      this.errorMessage = chatRes.msg || "request error";
      this.showErrorAlert = true;
      return;
    }
    let rooms: any[] = [];
    this.currentSearchChatsLength = chatRes.data.length;
    for (let i = 0; i < chatRes.data.length; i++) {
      let roomItem = chatRes.data[i];
      if (oriChatData.find((item) => item.room_id === roomItem.room_id))
        continue;
      let roomNameAndAvatar = getRoomNameAndAvatarByRoom(roomItem, platform);
      let myRoomObj = {
        ...roomItem,
        ...roomNameAndAvatar,
        last_message: "",
        last_message_time: 0,
        un_read_count: 0,
      };
      if (roomItem.latest_msg) {
        let lastMessageData = roomItem.latest_msg;
        switch (lastMessageData.msg_type) {
          case MESSAGE_TYPE_ENUM.TEXT:
            myRoomObj.last_message = lastMessageData.msg_contents;
            break;
          case MESSAGE_TYPE_ENUM.CONTRACT_CREATED:
            myRoomObj.last_message = "New Contract!";
            break;
          case MESSAGE_TYPE_ENUM.CONTRACT_CONFIRMED:
            myRoomObj.last_message = "Contract Confirmed!";
            break;
          case MESSAGE_TYPE_ENUM.CONTRACT_REJECTED:
            myRoomObj.last_message = "Contract Rejected!";
            break;
          default:
            myRoomObj.last_message = "";
        }
        myRoomObj.last_message_time = lastMessageData.created_at / 1000000;
      }
      rooms.push(myRoomObj);
    }
    this.chats = [...oriChatData, ...rooms];
  }
}
