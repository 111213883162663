import React, { useState } from "react";

import "./index.scss";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useStore } from "src/services/mobx/service";
import { observer } from "mobx-react-lite";
import * as ChatApi from "../../../../services/api/chat";
import { closeOutline, push } from "ionicons/icons";
import { getUserInfoByJWT } from "../../../../constant/utils";
import SelectUser from "./components/SelectUser";
import { MESSAGE_TYPE_ENUM } from "../../../../constant/enum";

const pushMessageRadioFalse = require("../../../../assert/svg/pushMessageRadioFalse.svg");
const pushMessageRadioTrue = require("../../../../assert/svg/pushMessageRadioTrue.svg");

enum PushMessageType {
  CHAT = "chat",
  NOTIFICATION = "notification",
  All = "all",
}

const BulkMessageModal: React.FC = observer(() => {
  const [pushType, setPushType] = useState<PushMessageType>(
    PushMessageType.All
  );
  const [messageTextarea, setMessageTextarea] = useState("");
  const store = useStore();

  const handlePushTypeChange = (type: PushMessageType) => {
    setPushType(type);
  };

  const handleSubmitNotification = async () => {
    const userInfo = getUserInfoByJWT();
    let params = {
      version: 1,
      sender_id: userInfo.user_id,
      to: store.chatStates.pushMessageSelectedUsers.map((item) => item.user_id),
      notification_type: "text",
      notification_payload: messageTextarea,
    };
    await ChatApi.pushNotification(params);
  };
  const handleSubmitChat = async () => {
    for (let i = 0; i < store.chatStates.pushMessageSelectedUsers.length; i++) {
      let currentItem = store.chatStates.pushMessageSelectedUsers[i];
      let roomData = await ChatApi.getRooms({
        user_id: currentItem.user_id,
      });
      if (roomData.data) {
        // 创建room 发消息到room
        let loginUserInfo = getUserInfoByJWT();
        let params = {
          to: roomData.data,
          msg_type: MESSAGE_TYPE_ENUM.TEXT,
          msg_contents: messageTextarea,
          from_uid: loginUserInfo.user_id,
        };
        await store.sendMessage(JSON.stringify(params));
      }
    }
  };

  const submitSendData = async () => {
    if (store.chatStates.pushMessageSelectedUsers.length <= 0) {
      return false;
    }
    console.log(pushType, 'pushType')
    if (pushType === PushMessageType.NOTIFICATION) {
      await handleSubmitNotification();
    }
    if (pushType === PushMessageType.CHAT) {
      await handleSubmitChat();
    }
    if (pushType === PushMessageType.All) {
      await handleSubmitChat();
      await handleSubmitNotification();
    }
    setMessageTextarea("");
    store.chatStates.setPushMessageSelectedUsers([]);
    store.chatStates.setShowModal(false);
    store.chatStates.setShowModalType("");
  };

  return (
    <IonPage className="bulk-message-modal-page">
      <IonHeader
        className="house-contract-modal-header"
        style={{ paddingLeft: "18px" }}
      >
        <IonToolbar>
          <div className="sudo-swap-header-title">Bulk messaging</div>
          <IonButtons slot="end" className="ion-close-icon done-button">
            <IonButton
              onClick={() => {
                store.chatStates.setShowModal(false);
                store.chatStates.setShowModalType("");
              }}
            >
              <IonIcon
                style={{ color: "#000" }}
                slot="icon-only"
                icon={closeOutline}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className="bulk-message-modal-content"
        onClick={() => {
          store.chatStates.setSearching(false);
        }}
      >
        <div className="bulk-message-title">
          Sending a message to multiple contacts or group chats at the same time
        </div>
        <div
          className="push-to-list"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="title">Send to</div>
          <SelectUser style={{ marginTop: "8px" }} />
        </div>
        <div className="push-message-content">
          <div className="title">Message</div>
          <IonTextarea
            auto-grow
            rows={4}
            maxlength={500}
            className="welcome-message-textarea-value"
            value={messageTextarea}
            placeholder="Write something..."
            onIonChange={(e) => setMessageTextarea(e.detail.value!)}
          />
        </div>
        <div className="push-message-type">
          <div className="title">Send via</div>
          <div className="push-message-type-box">
            <div
              className="push-message-type-radio"
              onClick={() => {
                handlePushTypeChange(PushMessageType.All);
              }}
            >
              <img
                src={
                  pushType === PushMessageType.All
                    ? pushMessageRadioTrue
                    : pushMessageRadioFalse
                }
                alt=""
              />
              <div>All</div>
            </div>
            <div
              className="push-message-type-radio"
              onClick={() => {
                handlePushTypeChange(PushMessageType.CHAT);
              }}
            >
              <img
                src={
                  pushType === PushMessageType.CHAT
                    ? pushMessageRadioTrue
                    : pushMessageRadioFalse
                }
                alt=""
              />
              <div>Chat</div>
            </div>
            <div
              className="push-message-type-radio"
              onClick={() => {
                handlePushTypeChange(PushMessageType.NOTIFICATION);
              }}
            >
              <img
                src={
                  pushType === PushMessageType.NOTIFICATION
                    ? pushMessageRadioTrue
                    : pushMessageRadioFalse
                }
                alt=""
              />
              <div>Notification</div>
            </div>
          </div>
        </div>
      </IonContent>
      <IonFooter className="bulk-message-modal-footer">
        <IonButton
          className="send-button"
          expand="block"
          onClick={submitSendData}
        >
          Send
        </IonButton>
      </IonFooter>
    </IonPage>
  );
});
export default BulkMessageModal;
