import { action, makeAutoObservable, observable } from "mobx";
import React from "react";
import AuthStore from "./modules/AuthStore";
import ChatStore from "./modules/ChatStore";
import HomeStore from "./modules/HomeStore";
import {AUTH_STORE_KEY_ENUM, MESSAGE_TYPE_ENUM, MODAL_TYPE_ENUM} from "../../constant/enum";
import moment from "moment";
import * as ChatApi from "../api/chat";
import {
  getAccessToken,
  getRoomNameAndAvatarByRoom,
  getUserInfoByJWT, getUserInfoByMap,
  getUserNickName, hasNotifyPermission, isCurrentWindow, isMobile, notifyMessage,
} from "../../constant/utils";
import { WS_ROOT_URL } from "../../constant";
import NFTSwapStore from "./modules/NFTSwapStore";
import mqtt, { MqttClient } from "mqtt";
import { tokenMgr } from "../axios/axios";
// interface AppStates{
//     testVariable: number;
// }
//
// let defaultValue:AppStates={
//     testVariable: 0,
// }

export default class AppStore {
  // @observable appStates:AppStates = defaultValue;
  // @observable onboardBufferObj:OnBoardBufferObj = {};
  @observable authStates: AuthStore = new AuthStore();
  @observable chatStates: ChatStore = new ChatStore();
  @observable homeStates: HomeStore = new HomeStore();
  @observable NFTSwapStates: NFTSwapStore = new NFTSwapStore();
  @observable ws: any = null;

  // @observable showPostUploadModal2:boolean = false;

  @action resetStore() {
    this.authStates = new AuthStore();
    this.chatStates = new ChatStore();
    this.homeStates = new HomeStore();
    this.NFTSwapStates = new NFTSwapStore();
  }

  @action resetChatStore() {
    this.chatStates = new ChatStore();
  }

  @action logout() {
    this.authStates.setAuthStateData(
      AUTH_STORE_KEY_ENUM.IS_AUTHENTICATED,
      false
    );
    this.authStates.setAuthStateData(
      AUTH_STORE_KEY_ENUM.SHOW_ERROR_ALERT,
      false
    );
    this.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.SHOW_LOADING, false);
    this.authStates.setSessionToken("");
    // 清空walletconnect
    localStorage.removeItem('walletconnect')
    tokenMgr().setToken("");
    this.chatStates = new ChatStore();
    this.homeStates = new HomeStore();
    this.NFTSwapStates = new NFTSwapStore();
    try {
      this.ws.end();
    } catch (e) {
      console.log(e);
    }
  }

  // onmessage事件设置聊天列表
  @action
  async setNewChats(messageData: any) {
    let newChats = [];
    let newChat = this.homeStates.chats.find(
      (item) => item.room_id === messageData.to
    );
    if (!newChat) {
      const roomInfo = await ChatApi.getRoomInfo({
        room_id: messageData.to,
      });

      let roomNameAndAvatar = getRoomNameAndAvatarByRoom(
        roomInfo.data,
        this.authStates.platform
      );
      if (roomInfo.data) {
        newChat = {
          ...roomInfo.data,
          ...roomNameAndAvatar,
          last_message: "",
          last_message_time: 0,
          un_read_count: 0,
        };
      }
    }
    if (newChat) {
      switch (messageData.msg_type) {
        case MESSAGE_TYPE_ENUM.TEXT:
          newChat.last_message = messageData.msg_contents;
          break;
        case MESSAGE_TYPE_ENUM.CONTRACT_CREATED:
          newChat.last_message = "New Contract!";
          break;
        case MESSAGE_TYPE_ENUM.CONTRACT_CONFIRMED:
          newChat.last_message = "Contract Confirmed!";
          break;

        case MESSAGE_TYPE_ENUM.CONTRACT_REJECTED:
          newChat.last_message = "Contract Rejected!";
          break;
        case MESSAGE_TYPE_ENUM.SYSTEM_THREAD_CREATED:
          newChat.last_message = "New Thread!";
          break;
        default:
          newChat.last_message = "";
      }
      newChat.last_message_time = moment().utc();
      newChat.un_read_count += 1;
      newChats.push(newChat);
    }
    for (let i = 0; i < this.homeStates.chats.length; i++) {
      let currentChat = this.homeStates.chats[i];
      if (currentChat.room_id === messageData.to) {
        continue;
      }
      newChats.push(currentChat);
    }
    this.homeStates.chats = newChats;
  }

  // 页面滚动到最新消息位置
  @action scrollMessageDom() {
    const dom: any = document.getElementById("msg_end");
    if (dom) {
      dom.scrollIntoView();
    }
  }

  @action
  async setWs() {
    const userInfo = getUserInfoByJWT();
    let token = getAccessToken();
    try {
      this.ws.end();
    } catch (e) {
      console.log(e);
    }
    this.ws = mqtt.connect(`${process.env.REACT_APP_MQTT_BASE_URL}/mqtt`, {
      password: token,
      username: userInfo.user_id,
      clientId: userInfo.user_id,
    });
    // 请求所有room 并订阅
    let myRooms = await ChatApi.getMyRooms();
    myRooms.data &&
      myRooms.data.forEach((item: any) => {
        if (item) {
          this.ws.subscribe(`chat/${item}`);
          this.ws.subscribe(`notification/${item}`)
        }
      });

    this.ws.subscribe(`notification/${userInfo.user_id}`)

    if (this.ws) {
      this.ws.on("message", async (topic: string, message: string) => {
        let messageData = JSON.parse(message.toString());
        console.log(messageData, "messageData");
        if ('notification_type' in messageData) {
          // 是推送不是消息
          this.homeStates.addUnReadNotificationCount()
          if ( !isMobile()) {
            new Notification('New Notification' as string);
          }
          // if (this.chatStates.showModal && this.chatStates.showModalType === MODAL_TYPE_ENUM.BULK_MESSAGE) {
            console.log('添加数据到小铃铛')
            let userId = messageData.sender_id;
            const userInfo = await getUserInfoByMap(userId, this.homeStates.contacts);
            this.homeStates.notificationMessages.unshift({
              ...messageData,
              sender_info: userInfo,
            })

          // }
          return
        }
        messageData.fromUserInfo = {
          user_name: "Anyone",
          user_avatar: "",
        };

        if (messageData.from_uid !== userInfo.user_id && !isMobile()) {
          if (!isCurrentWindow() || messageData.to !== this.chatStates.roomId) {
            const hasPermission = await hasNotifyPermission()
            if (hasPermission) {
              const notifyMsg = notifyMessage(messageData);
              new Notification(notifyMsg as string);
            }
          }
        }
        // 设置chats聊天列表
        await this.setNewChats(messageData);
        // 说明不是在当前聊天室 return
        if (this.chatStates.roomId !== messageData.to) return;
        // 系统消息，不展示messages
        if (messageData.msg_type === MESSAGE_TYPE_ENUM.SYSTEM_THREAD_CREATED) {
          // 同步message
          await this.chatStates.setMessages(1, this.authStates.platform);
          // 同步threads
          await this.chatStates.getThreads();
          if (messageData.to === this.chatStates.roomId) {
            this.scrollMessageDom();
          }
          return;
        }
        if (
          messageData.belong_to_thread_id &&
          messageData.belong_to_thread_id !== this.chatStates.belongThreadId
        ) {
          // 当前消息有关联子话题    并且是在外面的聊天室
          // 对应场景：同一个聊天室，别人在thread中发消息 自己在外面
          this.chatStates.messages = this.chatStates.messages.map(
            (parentMessage) => {
              if (parentMessage.id === messageData.belong_to_thread_id) {
                parentMessage.subThreadMessageCount++;
                parentMessage.subThreadLastMessageInfo.msg_content =
                  messageData.msg_contents;
              }
              return parentMessage;
            }
          );
          if (messageData.to === this.chatStates.roomId) {
            this.scrollMessageDom();
          }
          return;
        }

        if (messageData.reply_to_msg_id) {
          // 当前消息是回复消息
          const replyMsgInfo = await ChatApi.getMessageById({
            msg_id: messageData.reply_to_msg_id,
          });
          if (replyMsgInfo.code === 0 && replyMsgInfo.data) {
            const userInfo = await this.chatStates.getMessageFromUserInfo(
              replyMsgInfo.data.from_uid
            );
            if (userInfo) {
              messageData.replyMsgInfo = {
                user_name: getUserNickName(userInfo, this.authStates.platform),
                msg_contents: replyMsgInfo.data.msg_contents,
              };
            }
          }
        }
        let exitsMsg = this.chatStates.messages.find(
          (item) => item?.id === messageData.id
        );
        if (exitsMsg && exitsMsg.id) {
          return;
        }
        let waitConfirmMsg = this.chatStates.messages.find(
          (item) => item.msg_random_secret === messageData.msg_random_secret
        );
        if (
          waitConfirmMsg &&
          waitConfirmMsg.sendStatus === "wait_callback_confirm"
        ) {
          this.chatStates.messages = this.chatStates.messages.map((item) => {
            if (item.msg_random_secret === messageData.msg_random_secret) {
              item = messageData;
            }
            return item;
          });
        } else {
          this.chatStates.messages.push(messageData);
        }

        if (messageData.to === this.chatStates.roomId) {
          this.scrollMessageDom();
        }
      });
    }
  }

  @action
  async sendMessage(data: any) {
    let publishKey = `msg/${getUserInfoByJWT().user_id}`;
    try {
      this.ws.publish(publishKey, data);
    } catch (e: any) {
      console.log(e, "e");
      await this.setWs();
      await this.ws.publish(publishKey, data);
    }
  }

  constructor() {
    makeAutoObservable(this); //even though this isn't required in some examples, this seems key line to making mobx work
  }
}

export const StoreContext = React.createContext(new AppStore());
export const StoreProvider = StoreContext.Provider;
export const useStore = () => React.useContext(StoreContext);
