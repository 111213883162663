import { action, makeAutoObservable, observable } from "mobx";
import * as ChatApi from "../../api/chat";

export default class NFTSwapStore {
  // @observable auth:AuthState = defaultValue;
  @observable showLoading: boolean = false;
  @observable showErrorAlert: boolean = false;
  @observable errorMessage: string = "something is wrong";
  @observable myAssets: any[] = [];

  constructor() {
    makeAutoObservable(this); //even though this isn't required in some examples, this seems key line to making mobx work
  }

  @action setShowLoading(data: boolean) {
    this.showLoading = data;
  }

  @action setShowErrorAlert(data: boolean) {
    this.showErrorAlert = data;
  }

  @action setErrorMessage(data: string) {
    this.errorMessage = data;
  }

  @action
  async getMyAssets(address: string, page: number = 1, size: number = 10) {
    let offset = (page - 1) * size;
    const assetsRes = await ChatApi.getOpenSeaAssets({
      owner: address,
      offset: offset,
      limit: size,
    });
    if (assetsRes.assets && assetsRes.assets.length > 0) {
      if (page === 1) {
        this.myAssets = assetsRes.assets;
      } else {
        this.myAssets = [...this.myAssets, assetsRes.assets];
      }
    } else {
      if (page === 1) {
        this.myAssets = [];
      }
    }
  }

  @action selectedOneNFT(item: any) {
    this.myAssets = this.myAssets.map(nftItem => {
      if (nftItem.id === item.id) {
        console.log(nftItem.id, 'nftItem.id')
        console.log(item.id, 'item.id')
        nftItem.isSelected = !item.isSelected
      }
      return nftItem
    })
  }

  @action cancelSelectNFT() {
    this.myAssets = this.myAssets.map(nftItem => {
      nftItem.isSelected = false
      return nftItem
    })
  }

}
