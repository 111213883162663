import {action, get, makeAutoObservable, observable} from "mobx";
import * as ChatApi from "../../api/chat";
import { UserInfoInterface } from "../../api/apiInterface/chatInterface";
import {getPlatformStatusByCode, PLATFORM_ENUM} from "../../../constant/enum";
import moment from "moment";
import {
  getRoomNameAndAvatarByRoom,
  getUserAvatar,
  getUserInfoByJWT,
  getUserNickName,
} from "../../../constant/utils";

interface roomInfoInterface {
  room_id: string;
  name: string;
  created_at: number;
  description: string;
  room_name: string;
  room_avatar: string;
  opensea_coll_cover?: string;
  is_1v1: boolean;
  is_twitter_space?: boolean
}

const PageSize = 500;

export default class ChatStore {
  // @observable auth:AuthState = defaultValue;
  @observable isPc: boolean = false;
  @observable toUserInfo: any = {
    twitter_username: "",
  };
  @observable showLoading: boolean = false;
  @observable showErrorAlert: boolean = false;
  @observable errorMessage: string = "something was wrong";
  @observable roomId: string = "";
  @observable sendButtonShow: boolean = false;
  @observable openMenuShow: boolean = false;
  @observable moreMessageShow: boolean = false;
  @observable disableInput: boolean = false;
  @observable showConfirmPopover: boolean = false;
  @observable showNotifyPopover: boolean = false;
  @observable messages: any[] = [];
  @observable currentContract: any = null;
  @observable chats: any[] = [];
  @observable threads: any = [];
  @observable belongThreadId: string = "";
  @observable showModal: boolean = false;
  @observable showModalType: string = "";
  @observable memberProfileInfo: any = null;
  @observable openseaBelongNFTItem: any = null
  @observable roomInfo: roomInfoInterface = {
    room_id: "...",
    name: "...",
    created_at: 0,
    description: "...",
    room_name: "...",
    room_avatar: "",
    is_1v1: true,
  };
  @observable roomMembers: UserInfoInterface[] = [];
  @observable roomCreator: UserInfoInterface = {
    created_at: 0,
    discord_username: "",
    eth_wallet_address: "",
    facebook_username: "",
    instagram_username: "",
    opensea_username: "",
    nick_name: "",
    status: '000000',
    twitter_username: "",
    user_id: "",
  };
  // 兼容，只在当前store中使用
  @observable belongMessageInfo: any = null;
  @observable replyMessageInfo: any = null;
  @observable getMessageCount: number = 0;
  @observable getThreadsCount: number = 0;
  @observable pushMessageSelectedUsers: UserInfoInterface[] = []
  @observable pushMessageUserSearching: boolean = false

  constructor() {
    makeAutoObservable(this); //even though this isn't required in some examples, this seems key line to making mobx work
  }
  @action setSearching(data: boolean) {
    this.pushMessageUserSearching = data
  }

  @action setPushMessageSelectedUsers(data: UserInfoInterface[])  {
    this.pushMessageSelectedUsers = data
  }

  @action delOneUserPushMessage(data: UserInfoInterface) {
    let oriData = this.pushMessageSelectedUsers
    let newData: UserInfoInterface[] = []
    oriData.forEach(item => {
      if (data.user_id !== item.user_id) {
        newData.push(item)
      }
    })
    this.pushMessageSelectedUsers = newData
  }

  @action addOneUserPushMessage(data: UserInfoInterface) {
    this.pushMessageSelectedUsers.push(data)
  }


  @action setShowLoading(data: boolean) {
    this.showLoading = data;
  }

  @action setMemberProfileInfo(data: UserInfoInterface) {
    this.memberProfileInfo = data;
  }

  @action emptyMemberProfileInfo() {
    this.memberProfileInfo = null;
  }

  @action setIsPc(data: boolean) {
    this.isPc = data;
  }

  @action setShowModal(data: boolean) {
    this.showModal = data;
  }

  @action setShowModalType(data: string) {
    this.showModalType = data;
  }

  @action setOpenMenuShow(data: boolean) {
    this.openMenuShow = data;
  }

  @action setShowConfirmPopover(data: boolean) {
    this.showConfirmPopover = data;
  }

  @action setShowNotifyPopover(data: boolean) {
    this.showNotifyPopover = data;
  }

  @action setCurrentContract(data: any) {
    this.currentContract = data;
  }

  @action setRoomId(data: string) {
    this.roomId = data;
  }

  @action setSendButtonShow(data: boolean) {
    this.sendButtonShow = data;
  }

  @action setShowErrorAlert(data: boolean) {
    this.showErrorAlert = data;
  }

  @action setDisableInput(data: boolean) {
    this.disableInput = data;
  }

  @action setMoreMessageShow(data: boolean) {
    this.moreMessageShow = data;
  }

  @action setBelongThreadId(data: string) {
    this.belongThreadId = data;
  }

  async resetBelongMessageInfo() {
    this.belongMessageInfo = null;
  }

  @action
  async setBelongMessageInfo(msgId: string, platform: PLATFORM_ENUM) {
    const getMessageInfoRes = await ChatApi.getMessageById({ msg_id: msgId });
    if (getMessageInfoRes.code !== 0) {
      this.showLoading = false;
      this.errorMessage = getMessageInfoRes.msg;
      this.showErrorAlert = true;
      return;
    }
    if (getMessageInfoRes.data) {
      console.log(getMessageInfoRes.data, 'getMessageInfoRes.data')
      if (getMessageInfoRes.data.opensea_item_token_id && getMessageInfoRes.data.opensea_item_contract_address) {
        let NFTData = await ChatApi.getNFTItemInfo({
          token_id: getMessageInfoRes.data.opensea_item_token_id,
          asset_contract_address: getMessageInfoRes.data.opensea_item_contract_address
        })
        if (NFTData) {
          this.openseaBelongNFTItem = {
            name: NFTData?.name || '',
            lastSaleData: moment(NFTData?.last_sale?.created_date).format('DD/MM/YYYY HH:mm'),
            creator: NFTData?.creator?.user?.username || ''
          }
        }

        console.log(NFTData, 'NFTData')
      }
      this.belongMessageInfo = getMessageInfoRes.data;
      this.belongMessageInfo.user_name = "Unnamed";
      this.belongMessageInfo.user_avatar = "";
      if (getMessageInfoRes.data.from_uid) {
        let userInfo = await this.getMessageFromUserInfo(
          getMessageInfoRes.data.from_uid
        );
        if (userInfo) {
          this.belongMessageInfo.user_name = getUserNickName(
            userInfo,
            platform
          );
          this.belongMessageInfo.user_avatar = getUserAvatar(
            userInfo,
            platform
          );
        }
      }
    } else {
      this.belongMessageInfo = null;
    }
  }

  @action setReplyMessageInfo(data: any) {
    this.replyMessageInfo = data;
  }

  @action
  async getMessageFromUserInfo(msgFromUid: string) {
    if (!msgFromUid || msgFromUid === '0') return null
    const loginUserInfo = getUserInfoByJWT();
    let userInfo = this.roomMembers.find((item) => item.user_id === msgFromUid);
    if (!userInfo) {
      if (msgFromUid === loginUserInfo.user_id) {
        userInfo = loginUserInfo;
      } else {
        let userRes = await ChatApi.userInfo({
          platform: "user_id",
          user_name: msgFromUid,
        });
        if (userRes.code !== 0) {
          this.showLoading = false;
          this.errorMessage = userRes.msg;
          this.showErrorAlert = true;
          return;
        }
        userInfo = userRes.data || null;
      }
    }
    return userInfo;
  }

  @action
  async setMessages(page: number = 1, platform: PLATFORM_ENUM) {
    let loginUserInfo = getUserInfoByJWT();
    console.log("ready to set message");
    let allMessages = await ChatApi.getMessages({
      room_id: this.roomId,
      page: page,
      size: PageSize,
    });

    let messagesData = allMessages;
    if (this.belongThreadId) {
      messagesData = await ChatApi.getThreads({
        room_id: this.roomId,
        belong_to_thread_id: this.belongThreadId,
        size: PageSize,
        page: page,
      });
    }

    if (messagesData.code !== 0) {
      this.showLoading = false;
      this.errorMessage = messagesData.msg;
      this.showErrorAlert = true;
      this.disableInput = true;
      return;
    }

    if (messagesData.data && messagesData.data.length > 0) {
      this.getMessageCount = messagesData.data.length;
      messagesData.data = messagesData.data.reverse();
      let messages: any[] = [];

      messagesData.data.forEach((item: any) => {
        // 兼容代码   可删除
        if (item.from_uid === '623975a4a501c3edbdffead1_1650111031043_noaecs') {
          item.from_uid = '623975a4a501c3edbdffead1'
        }
        if (!item.belong_to_thread_id || this.belongThreadId) {
          if (item.reply_to_msg_id) {
            // 获取messageInfo
            item.replyMsgInfo = {
              user_name: "",
              msg_contents: "",
            };
          }
          item.fromUserInfo = {
            user_name: "Anyone",
            user_avatar: "",
          };
          item.subThreadMessageCount = 0;
          item.subThreadLastMessageInfo = {
            user_avatar: "",
            user_name: "",
            msg_content: "",
            msg_time: 0,
            user_id: "",
          };
          messages.push(item);
        }
      });

      allMessages.data.forEach((thread: any) => {
        messages.forEach((message: any) => {
          if (
            thread.belong_to_thread_id &&
            message.id === thread.belong_to_thread_id
          ) {
            let msgFromUserInfo = this.roomMembers.find(
              (item) => item.user_id === thread.from_uid
            );
            if (thread.from_uid === loginUserInfo.user_id)
              msgFromUserInfo = loginUserInfo;
            message.subThreadMessageCount++;
            message.subThreadLastMessageInfo = {
              msg_time: moment
                .utc(thread.created_at / 1000000)
                .local()
                .format("MM/DD"),
              msg_content: thread.msg_contents,
              user_name: msgFromUserInfo ? msgFromUserInfo.nick_name : "",
              user_avatar: msgFromUserInfo
                ? getUserAvatar(msgFromUserInfo, platform)
                : "",
              user_id: msgFromUserInfo?.user_id,
            };
          }
        });
      });
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].reply_to_msg_id) {
          let replyMsgInfo = await ChatApi.getMessageById({
            msg_id: messages[i].reply_to_msg_id,
          });
          if (replyMsgInfo.code === 0 && replyMsgInfo.data) {
            let userInfo = await this.getMessageFromUserInfo(
              replyMsgInfo.data.from_uid
            );
            if (userInfo) {
              messages[i].replyMsgInfo = {
                user_name: getUserNickName(userInfo, platform),
                msg_contents: replyMsgInfo.data.msg_contents,
              };
            }
          }
        }

        let fromUserInfo = await this.getMessageFromUserInfo(
            messages[i].from_uid
        );
        if (fromUserInfo) {
          messages[i].fromUserInfo = {
            user_name: getUserNickName(fromUserInfo, platform),
            user_avatar: getUserAvatar(fromUserInfo, platform),
          };
        }
      }
      // thread和聊天室用同一个messages
      if (page !== 1) {
        if (messages.length > 0) {
          this.messages = messages.concat(this.messages);
        }
      } else {
        this.messages = messages;
      }
    } else {
      if (page === 1 && !this.isPc) {
        this.messages = [];
      }
    }
  }

  @action pushOneMessage(data: any) {
    this.messages.push(data);
  }

  @action setErrorMessage(data: string) {
    this.errorMessage = data;
  }

  @action
  async setToUserInfo(data: any, platform: any) {
    let userRes = await ChatApi.register({
      platform: platform,
      user_name: data,
    });
    if (!userRes.data) {
      this.showLoading = false;
      this.errorMessage = userRes.msg;
      this.showErrorAlert = true;
      this.disableInput = true;
      return;
    }
    // this.toUserInfo = {
    //   name: data,
    //   id: userRes.data
    // }
    this.toUserInfo = userRes.data;
  }

  @action
  async getThreads(page: number = 1) {
    // 获取room下所有的评平级hreads
    const getThreadsRes = await ChatApi.getThreads({
      room_id: this.roomId,
      size: PageSize,
      page: page,
    });
    if (getThreadsRes.code !== 0) {
      this.showLoading = false;
      this.errorMessage = getThreadsRes.msg;
      this.showErrorAlert = true;
      return;
    }
    if (getThreadsRes.data && getThreadsRes.data.length > 0) {
      this.getThreadsCount = getThreadsRes.data.length;
      if (page !== 1) {
        this.threads = getThreadsRes.data.concat(this.threads);
      } else {
        this.threads = getThreadsRes.data;
      }
    } else {
      if (page === 1) {
        this.threads = [];
      }
    }
  }

  @action
  async initRoomInfo(platform: PLATFORM_ENUM) {
    const roomInfoRes = await ChatApi.getRoomInfo({
      room_id: this.roomId,
    });
    if (roomInfoRes.code !== 0) {
      this.showLoading = false;
      this.errorMessage = roomInfoRes.msg;
      this.showErrorAlert = true;
      return;
    }
    const roomInfo = roomInfoRes.data;
    const roomNameAvatar = getRoomNameAndAvatarByRoom(roomInfo, platform);
    let myRoomObj: roomInfoInterface = {
      ...roomNameAvatar,
      is_twitter_space: roomInfo?.is_twitter_space || false,
      room_id: roomInfo.room_id,
      name: roomInfo.name,
      created_at: roomInfo.created_at,
      description: roomInfo.description,
      is_1v1: roomInfo.is_1v1,
    };

    if (roomInfo.members && roomInfo.members.length > 0) {
      let readyRoomMembers = roomInfo.members.map(item => {
        item.rest_id = ''
        return item
      });
      if (roomInfo.is_1v1) {
        for (let i = 0; i < readyRoomMembers.length; i++) {
          let currentMember = roomInfo.members[i]
          let status = getPlatformStatusByCode(currentMember.status, PLATFORM_ENUM.TWITTER);
          if (!status && currentMember.twitter_username) {
            //没有验证过twitter  绑定用户twitter_id
            const twitterUser = await ChatApi.getTwitterUserInfo(currentMember.twitter_username)
            if (twitterUser.data) {
              currentMember.rest_id =twitterUser.data.rest_id
            }
          }
        }
      }
      this.roomMembers = readyRoomMembers
    }
    this.roomInfo = myRoomObj;
    if (roomInfo.creator) {
      this.roomCreator = roomInfo.creator;
    }
  }

  setThreadsTree(list: any[]) {
    const copyList = list.slice(0);
    const tree = [];
    for (let i = 0; i < copyList.length; i++) {
      // 找出每一项的父节点，并将其作为父节点的children
      for (let j = 0; j < copyList.length; j++) {
        if (copyList[i].belong_to_thread_id === copyList[j].id) {
          if (copyList[j].sub_thread === undefined) {
            copyList[j].sub_thread = [];
          }
          copyList[j].sub_thread.push(copyList[i]);
        }
      }
      // 把根节点提取出来，parentId为null的就是根节点
      if (!copyList[i].belong_to_thread_id) {
        tree.push(copyList[i]);
      }
    }
    return tree;
  }
}
