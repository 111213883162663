import React, {useEffect, useState} from "react";

import "./index.scss";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon, IonInfiniteScroll, IonInfiniteScrollContent,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { closeOutline } from "ionicons/icons";
import copy from "copy-to-clipboard";
import { useStore } from "../../../../../services/mobx/service";
import {
  getHref,
  getUserAvatar,
  getUserNickName,
} from "../../../../../constant/utils";
import moment from "moment";

const copyIcon = require("../../../../../assert/svg/copyIcon.svg");
const userIcon = require("../../../../../assert/svg/user.svg");

/**
 *  粉丝和主播聊天
 */
const size = 10;
const HomeNotifyContent: React.FC = observer(() => {
  const [present] = useIonToast();
  const store = useStore();
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState(false);

  const loadData = async (ev: any) => {
    let currentPage = (store.homeStates.notificationMessages.length / size).toFixed(0);
    let item = +currentPage + 1;
    await getNotifications(item);
    ev.target.complete();
  };

  const getNotifications = async (page: number = 1) => {
    await store.homeStates.getNotificationList({ page, size });
    console.log(store.homeStates.notificationCurrentSearchLength, 'store.homeStates.currentSearchChatsLength')
    if (store.homeStates.notificationCurrentSearchLength <  size) {
      setIsInfiniteDisabled(true)
    }
    setTimeout(async () => {
      if (store.homeStates.notificationMessages.length > 0) {
        let unReadIds = []
        for (let i = 0; i < store.homeStates.notificationMessages.length; i++) {
          let currentItem = store.homeStates.notificationMessages[i];
          if (!currentItem.has_been_read) {
            unReadIds.push(currentItem.id)
          }
        }
        await store.homeStates.readNotificationMessage(unReadIds)
      }
    }, 1000)
  }

  useEffect(() => {
    const init = async () => {
      await getNotifications()
    };
    init();
  }, []);

  return (
    <IonPage className="home-notify-page">
      <IonHeader className="home-notify-header">
        <IonToolbar>
          <IonTitle>Notification</IonTitle>
          <IonButtons slot="end" className="ion-close-icon done-button">
            <IonButton
              onClick={() => store.chatStates.setShowNotifyPopover(false)}
            >
              <IonIcon
                style={{ color: "#000" }}
                slot="icon-only"
                icon={closeOutline}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="notify-box">
          <div className="is-read-box">
            <div className="un-read-box"></div>
          </div>
          <div className="notify-emoji-box">📣</div>
          <div className="chats-notify-content">
            <div className="notify-text-box">
              You’ve received new invite codes
            </div>
            <div className="notify-text-box">
              Share to friends to help them gain access to SwapChat.
            </div>
            {store.authStates.inviteCodes.length > 0 &&
                store.authStates.inviteCodes.map((codeItem: any, index) => {
                  return (
                      <div key={index} className="invite-code-item-box">
                        <div
                            className={
                              codeItem.is_used
                                  ? "invite-code-item-used"
                                  : "code-button"
                            }
                            onClick={() => {
                              if (codeItem.is_used) return;
                              let copyRes = copy(codeItem.invite_code);
                              if (copyRes) {
                                present("Copied", 1000);
                              }
                            }}
                        >
                          {codeItem.invite_code}
                        </div>
                        <div className="code-hover-box">
                          {codeItem.is_used ? (
                              <div className="code-hover-used-text">
                                {" "}
                                This code has been used{" "}
                              </div>
                          ) : (
                              <div>
                                <div>Available</div>
                                <img src={copyIcon} alt="" />
                              </div>
                          )}
                        </div>
                      </div>
                  );
                })}
            {/*<div className="notify-text-date">Today at 11:03 AM</div>*/}
          </div>
        </div>
        {store.homeStates.notificationMessages.length > 0 &&
            store.homeStates.notificationMessages.map((item, index) => {
              return (
                  <div className="notify-box" key={index}>
                    <div className="is-read-box">
                      <div className="un-read-box" style={{ background: item.has_been_read ? '#fff': '#F5365C' }}></div>
                    </div>
                    <div className="notify-emoji-box">
                      <img
                          className="select-user-avatar-pre"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = userIcon;
                          }}
                          src={
                              getUserAvatar(
                                  item.sender_info,
                                  store.authStates.platform
                              ) || userIcon
                          }
                          alt=""
                      />
                    </div>
                    <div className="chats-notify-content">
                      <div className="notify-text-box">
                        <div>
                          {getUserNickName(
                              item.sender_info,
                              store.authStates.platform
                          )}
                        </div>
                        {item.created_at > 0 && (
                            <div className="message-create-at">
                              {moment
                                  .utc(item.created_at / 1000000)
                                  .local()
                                  .format("MM/DD HH:mm")}
                            </div>
                        )}
                      </div>
                      <div
                          className="notify-text-box"
                          dangerouslySetInnerHTML={{
                            __html: getHref(item.notification_payload),
                          }}
                      />
                    </div>
                  </div>
              );
            })}
        <IonInfiniteScroll
            onIonInfinite={loadData}
            threshold="30px"
            disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more data"
          />
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
});
export default HomeNotifyContent;
