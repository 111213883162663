export enum CONTRACT_STATUS_ENUM {
  CREATED = "created",
  CONFIRMED = "confirmed",
  REJECTED = "rejected",
}

export enum MESSAGE_TYPE_ENUM {
  CONTRACT_CREATED = "contract_created",
  CONTRACT_CONFIRMED = "contract_confirmed",
  CONTRACT_REJECTED = "contract_rejected",
  TEXT = "text",
  SYSTEM = "system",
  SYSTEM_THREAD_CREATED = "system.thread_created",
  SUDO_SWAP_CARD = "sudo_swap_card",
}

export enum PLATFORM_ENUM {
  TWITTER = "twitter",
  DISCORD = "discord",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  OPENSEA = "opensea",
}
export enum PLATFORM_STATUS_NUMBER_ENUM {
  eth,
  twitter,
  instagram,
  facebook,
  discord,
  opensea,
  invited,
}

export function getStatusPlatform(str: string): PLATFORM_STATUS_NUMBER_ENUM {
  if (str === "eth") return PLATFORM_STATUS_NUMBER_ENUM.eth;
  if (str === "twitter") return PLATFORM_STATUS_NUMBER_ENUM.twitter;
  if (str === "instagram") return PLATFORM_STATUS_NUMBER_ENUM.instagram;
  if (str === "facebook") return PLATFORM_STATUS_NUMBER_ENUM.facebook;
  if (str === "discord") return PLATFORM_STATUS_NUMBER_ENUM.discord;
  if (str === "opensea") return PLATFORM_STATUS_NUMBER_ENUM.opensea;
  if (str === "invited") return PLATFORM_STATUS_NUMBER_ENUM.invited;
  return PLATFORM_STATUS_NUMBER_ENUM.eth;
}

export function getPlatformStatusByCode(code: string, platformStr: string) {
  if (!code) return false;
  let platform = getStatusPlatform(platformStr);
  let status = code[platform];
  if (status) {
    return Boolean(Number(status));
  } else {
    return false;
  }
}

export enum AUTH_STORE_KEY_ENUM {
  SHOW_LOADING = "showLoading",
  SHOW_ERROR_ALERT = "showErrorAlert",
  ERROR_MESSAGE = "errorMessage",
  BACK_URL = "backUrl",
  IS_AUTHENTICATED = "isAuthenticated",
  FROM_PAGE = "fromPage",
  LOGIN_USER_INFO = "loginUserInfo",
}

export enum MODAL_TYPE_ENUM {
  CREATE_CONTRACT = "create_contract",
  ROOM_MEMBERS = "room_members",
  TRANSFER_NFT = "transfer_nft",
  MEMBER_PROFILE = "member_profile",
  SUDO_SWAP = "sudo_swap",
  BULK_MESSAGE = 'bulk_message'
}
