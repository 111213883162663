import React, { useState } from "react";

// import "./index.scss";
import '../VerifyTwitterPage/index.scss'
import {IonAlert, IonButton, IonPage, useIonToast} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useStore } from "../../services/mobx/service";
import { AUTH_STORE_KEY_ENUM } from "../../constant/enum";
import { getEthAccount } from "../../constant/utils";
import copy from "copy-to-clipboard";
import {DISCORD_VERIFY_URL} from "../../constant";
import xxhash from "xxhash-wasm";

const clickHereLine = require("../../assert/img/clickHereLine.png");

/**
 *  粉丝和主播聊天
 */
const VerifyDiscordPage: React.FC = observer(() => {
  const history = useHistory();
  const [present] = useIonToast();
  const store = useStore();

  const [twitterUrl, setTwitterUrl] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const copyMsgToDiscord = async () => {
    // @ts-ignore
    let address = window.ethereum.selectedAddress;
    if (!address) {
      let account = await getEthAccount();
      address = account.address;
    }
    const XXHash = await xxhash();
    let str = `#Verify I am verifying my Discord account for SwapChat & Web3MQ, my verification code is: ${XXHash.h64ToString(
        address.toLocaleLowerCase()
    )}`
    let copyRes = copy(str);
    if (copyRes) {
      present("Copied", 1000);
      setDisableButton(false)
    }
  };

  return (
    <IonPage className="house-chat-bind-platform-page">
      {submitSuccess ? (
        <div className="bind-platform-dialog">
          <div className="bind-success-img-box">
            <img src={clickHereLine} alt="" />
          </div>

          <div className="bind-success-text-box">
            Verify Twitter account success
          </div>
          <div className="bind-success-text-box">click x to start chat</div>
        </div>
      ) : (
        <div className="bind-platform-dialog">
          <div className="bind-platform-dialog-header">
            <h1 className="bind-platform-dialog-header-title">
              Verify your Discord account
            </h1>
          </div>
          <div className="bind-platform-dialog-content">
            <div className="bind-platform-dialog-box">
              <div className="step-item-box">
                <div className="step-item-box-circle-box">1</div>
                <h1>Step 1</h1>
                <p>Click the button below to copy secret code. </p>
                <div className="step-item-box-button">
                  <IonButton
                    className="submit-url-button"
                    expand="block"
                    onClick={() => {
                      setTimeout(copyMsgToDiscord, 1000)
                    }}
                  >
                    Copy Secret Code
                  </IonButton>
                </div>
              </div>
              <div className="step-item-box">
                <div className="step-item-box-circle-box">2</div>
                <h1>Step 2</h1>
                <p>Click to join SwapChat server.</p>
                <div className="step-item-box-button">
                  <IonButton
                    disabled={disableButton}
                    className="submit-url-button"
                    expand="block"
                    onClick={() => {
                      //打开discord邀请链接
                      window.open(DISCORD_VERIFY_URL, "_blank");
                    }}
                  >
                    Join SwapChat Server
                  </IonButton>
                </div>
              </div>
            </div>
            <div className="step-item-box final-step-item-box">
              <div className="step-item-box-circle-box">3</div>
              <h1>Step 3</h1>
              <p>Hit the button and finish verify.</p>
              <div className="step-item-box-button">
                <IonButton
                  className="submit-url-button"
                  expand="block"
                  onClick={() => {
                    store.logout()
                    history.replace('/chat/auth')
                  }}
                >
                  Finish
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      )}
      <IonAlert
        isOpen={store.authStates.showErrorAlert}
        onDidDismiss={() =>
          store.authStates.setAuthStateData(
            AUTH_STORE_KEY_ENUM.SHOW_ERROR_ALERT,
            false
          )
        }
        header={"Error"}
        message={store.authStates.errorMessage}
        buttons={["Dismiss"]}
      />
    </IonPage>
  );
});
export default VerifyDiscordPage;
