import React, {useState} from "react";

import "./index.scss";
import {IonAlert, IonButton, IonContent, IonInput, IonPage,} from "@ionic/react";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router-dom";
import {useStore} from "../../services/mobx/service";
import * as LoginApi from "../../services/api/login";
import {AUTH_STORE_KEY_ENUM} from "../../constant/enum";
import {getUserInfoByJWT} from "../../constant/utils";

/**
 *  粉丝和主播聊天
 */
const VerifyCodePage: React.FC = observer(() => {
  const history = useHistory();
  const store = useStore();
  const [inviteCode, setInviteCode] = useState("");
  const [disableButton, setDisableButton] = useState(true);

  const submitInviteCode = async () => {
    setDisableButton(true);
    store.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.SHOW_LOADING, true);
    if (!inviteCode) return;
    const res = await LoginApi.submitInvitedCode(inviteCode);
    if (res.code === 0) {
      store.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.SHOW_LOADING,false);
      // 刷新token
      store.authStates.setSessionToken(res.data.access_token);
      let userInfo = getUserInfoByJWT();
      if (userInfo.eth_wallet_address) {
        document.title = `SwapChat - ${userInfo.eth_wallet_address}`;
      }
      if (store.authStates.backUrl) {
        history.replace(store.authStates.backUrl);
      } else {
        history.replace("/chat/home");
      }
      return;
    }
    store.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.ERROR_MESSAGE, res.msg);
    store.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.SHOW_ERROR_ALERT, true);
  };
  return (
    <IonPage className="house-chat-verify-code-page">
      <IonContent className="house-chat-verify-code-page-content">
        <div className="verify-code-page-content">
          <div className="verify-code-text-box">
            <p className="up-text">Please enter the invitation code</p>
          </div>
          <div className="invite-code-input">
            <IonInput
              className="inputComment"
              value={inviteCode}
              placeholder="Enter invite code"
              onIonChange={(e) => {
                setDisableButton(false);
                setInviteCode(e.detail.value!);
              }}
            />
          </div>
          <div className="button-box">
            <IonButton
              className="submit-code-button"
              expand="block"
              disabled={disableButton}
              onClick={submitInviteCode}
            >
              Submit
            </IonButton>
          </div>
          <p>Already registered?</p>
          <p>
            Click here to{" "}
            <span
              style={{ color: "#5669FF" }}
              onClick={() => {
                store.logout();
                history.replace("/chat/auth");
              }}
            >
              reconnect MetaMask
            </span>
          </p>
        </div>
      </IonContent>
      <IonAlert
        isOpen={store.authStates.showErrorAlert}
        onDidDismiss={() => {
          store.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.ERROR_MESSAGE, "");
          store.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.SHOW_ERROR_ALERT, false);
        }}
        header={"Error"}
        message={store.authStates.errorMessage}
        buttons={["Dismiss"]}
      />
    </IonPage>
  );
});
export default VerifyCodePage;
