import React, { Suspense, useEffect } from "react";
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { useStore } from "../../services/mobx/service";

import "./index.scss";
import Chats from "./Chats";
import Contacts from "./Contacts";
import Home from "./Home";
import {
  getAccessToken,
  getUserInfoByJWT,
  isLogin,
} from "../../constant/utils";
import { getPlatformStatusByCode, PLATFORM_ENUM } from "../../constant/enum";

const chatsIcon = require("../../assert/svg/chats.svg");
const chatsHoverIcon = require("../../assert/svg/chats-hover.svg");
const contactsHoverIcon = require("../../assert/svg/contacts-hover.svg");
const contactsIcon = require("../../assert/svg/contacts.svg");
const homeIcon = require("../../assert/svg/home.svg");
// Import SVG

const Tabs: React.FC = observer(() => {
  const history = useHistory();
  const store = useStore();
  useEffect(() => {
    const init = async () => {
      if (!getAccessToken()) {
        console.log("从tabs组件跳转回auth页面");
        store.logout();
        history.replace("/chat/auth");
        return;
      }
      let loginUserInfo = getUserInfoByJWT();
      if (
        ![PLATFORM_ENUM.TWITTER, PLATFORM_ENUM.DISCORD].includes(
          store.authStates.platform
        )
      ) {
        return true;
      }
      let checkRes = getPlatformStatusByCode(
        loginUserInfo.status,
        store.authStates.platform
      );
      if (!checkRes) {
        if (store.authStates.platform === PLATFORM_ENUM.TWITTER) {
          history.replace(
            `/chat/verifyTwitterPage?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`
          );
        }
        if (store.authStates.platform === PLATFORM_ENUM.DISCORD) {
          let url = `${window.location.origin}/chat/verifyDiscordPage/?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`;
          history.replace(
            `/chat/verifyDiscordPage?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`
          );
          // window.open(url, "_blank");
        }
      }
      // 获取联系人列表
       await store.homeStates.getContacts(1)
       await store.homeStates.getUserFollowers(1)
    };
    init();
  }, []);
  //@ts-ignore
  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          <Suspense fallback={<div>Loading</div>}>
            {/* <Route path='/home/tab1' render={() => (store.appStates.isAuthenticated ? <Tab1 /> : <Redirect to='/login' />)} /> */}
            <Route
              path="/chat/home"
              render={() => <Redirect to="/chat/home/chats" />}
              exact={true}
            />
            <Route
              path="/chat/home/chats"
              render={() =>
                isLogin() ? <Chats /> : <Redirect to="/chat/auth" />
              }
            />
            <Route
              path="/chat/home/contacts"
              render={() =>
                isLogin() ? <Contacts /> : <Redirect to="/chat/auth" />
              }
            />
            <Route
              path="/chat/home/homepage"
              render={() =>
                isLogin() ? (
                  <Home />
                ) : (
                  <Redirect to="/chat/auth" />
                )
              }
              exact={true}
            />
          </Suspense>
        </IonRouterOutlet>

        <IonTabBar className="house-super-chat-tab-bar" slot="bottom">
          <IonTabButton className="tabButton" tab="tab1">
            <div
              className="tabIcon"
              onClick={() => {
                history.replace("/chat/home/chats");
              }}
            >
              <img
                src={
                  history.location.pathname === "/chat/home/chats"
                    ? chatsHoverIcon
                    : chatsIcon
                }
                alt=""
              />
            </div>
          </IonTabButton>
          <IonTabButton className="tabButton" tab="tab2">
            <div
              className="monetizationIcon"
              onClick={() => {
                history.replace("/chat/home/contacts");
              }}
            >
              <img
                src={
                  history.location.pathname === "/chat/home/contacts"
                    ? contactsHoverIcon
                    : contactsIcon
                }
                alt=""
              />
            </div>
          </IonTabButton>
          <IonTabButton className="tabButton" tab="tab3">
            <div
              className="tabIcon"
              onClick={() => {
                history.replace("/chat/home/homepage");
              }}
            >
              <img src={homeIcon} alt="" />
            </div>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
});

export default Tabs;
