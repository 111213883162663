import React, { useEffect, useState } from "react";

import "./index.scss";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonPage,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useHistory, Redirect, Route } from "react-router-dom";
import { useStore } from "../../../services/mobx/service";
import { logEvent } from "../../../utils/analytics";
import MemberProfileModal from "../../ChatWebPage/components/MemberProfileModal";
import {getUserInfoByJWT} from "../../../constant/utils";
/**
 *  粉丝和主播聊天
 */
const Home: React.FC = observer(() => {
  const history = useHistory();
  const store = useStore();
  useEffect(() => {
    logEvent("home-page", "visit");
  }, []);

  return (
    <IonPage className="house-super-home-page">
      <IonContent>
        <MemberProfileModal userInfo={getUserInfoByJWT()} />
      </IonContent>
    </IonPage>
  );
});
export default Home;
