import axiosApiInstance from "../axios/axios";
import {
    GetLoginRandomSecretParams,
    GetLoginRandomSecretResponse,
    LoginParams,
    LoginResponse
} from "./apiInterface/loginInterface";


export async function getLoginRandomSecret (params: GetLoginRandomSecretParams) :Promise<GetLoginRandomSecretResponse> {
    return await axiosApiInstance.post('/login_random_secret', params)
}

export async function login (params: LoginParams) :Promise<LoginResponse> {
    return await axiosApiInstance.post('/login', params)
}

export async function submitInvitedCode (params: string) :Promise<LoginResponse> {
    return await axiosApiInstance.post('/verify_platform', {
        data: params,
        platform: 'invite_code'
    })
}

export async function verifyTwitterAccount (params: string) :Promise<LoginResponse> {
    return await axiosApiInstance.post('/verify_platform', {
        data: params,
        platform: 'twitter'
    })
}