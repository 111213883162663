import React, { Suspense, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonRouterOutlet,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import ChatWebPage from "./pages/ChatWebPage";
import VerifyTwitterPage from "./pages/VerifyTwitterPage";

import { observer } from "mobx-react-lite";

/* Core CSS required for Ionic components to work properly */
import "./App.css";
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Auth from "./pages/Auth";
import Tabs from "./pages/Tabs";
import { useStore } from "./services/mobx/service";
import VerifyCodePage from "./pages/VerifyCodePage";
import NFTSwapPage from "./pages/NFTSwapPage";
import VerifyDiscordPage from "./pages/VerifyDiscordPage";
import {initGA} from "./utils/analytics";
import {getUserInfoByJWT, hasNotifyPermission, isMobile} from "./constant/utils";
import Web3 from "web3";
import {AUTH_STORE_KEY_ENUM} from "./constant/enum";
// @ts-ignore
const web3 = new Web3(window.ethereum);
const App: React.FC = observer(() => {
  const store = useStore();
  setupIonicReact({
    mode: 'ios',
  });


  useEffect(() => {
    const init = async () => {
      store.authStates.setAuthStateData(
        AUTH_STORE_KEY_ENUM.SHOW_LOADING,
        false
      );
      // @ts-ignore
      if (!isMobile()) {
        initGA("G-YGJTM68TRT");
        await hasNotifyPermission();
      }
      if (getUserInfoByJWT()) {
        let userInfo = getUserInfoByJWT();
        if (userInfo.eth_wallet_address) {
          document.title = `SwapChat ${userInfo.eth_wallet_address}`;
        }
      }
    };
    store.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.SHOW_LOADING, true);
    setTimeout(init, 1000); // 3 seconds
  }, []);

  //get s
  return (
    <IonApp>
      <IonContent>
        <IonReactRouter>
          <IonRouterOutlet>
            <Suspense fallback={<div>Loading</div>}>
              <Route
                path='/'
                render={() => <Redirect to='/chat/auth' />}
                exact={true}
              />
              <Route
                path='/chat'
                render={() => <Redirect to='/chat/auth' />}
                exact={true}
              />
              <Route path='/chat/auth' component={Auth} exact={true} />
              <Route path='/chat/home' component={Tabs} />
              <Route path='/chat/verifyTwitterPage' component={VerifyTwitterPage} />
              <Route path='/chat/verifyCodePage' component={VerifyCodePage} />
              <Route path='/chat/verifyDiscordPage' component={VerifyDiscordPage} />
              <Route path='/chat/NftSwapPage' component={NFTSwapPage} />
              <Route
                path='/chat/chatWebPage'
                component={ChatWebPage}
                exact={true}
              />
            </Suspense>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonContent>
    </IonApp>
  );
});

export default App;
