import { IonIcon, IonInput } from "@ionic/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import "./index.scss";
import { addCircleSharp, closeCircle } from "ionicons/icons";
import { MESSAGE_TYPE_ENUM, MODAL_TYPE_ENUM } from "../../constant/enum";
import { getRandomStr, getUserInfoByJWT } from "../../constant/utils";
import { useStore } from "../../services/mobx/service";
import { observer } from "mobx-react-lite";
import { useDebounceFn } from "ahooks";

const sendMessageIcon = require("../../assert/svg/sendMessageIcon.svg");
const replyMessageCloseIcon = require("../../assert/svg/replyMessageCloseIcon.svg");
const transferIcon = require("../../assert/svg/transferIcon.svg");
const nftSwapIcon = require("../../assert/svg/nftSwap.svg");
const sudoSwapIcon = require("../../assert/svg/sudoswapIcon.svg");
const jinggaoIcon = require("../../assert/svg/jinggaoIcon.svg");
const bulkMessageIcon = require("../../assert/svg/bulkMessageIcon.svg");

const xss = require("xss");

const ChatPageFooter: React.FC = () => {
  const store = useStore();
  const [readySendMessage, setReadySendMessage] = useState("");
  let loginUserInfo = getUserInfoByJWT();
  const { run } = useDebounceFn(
    () => {
      store.chatStates.setSendButtonShow(false);
      if (!readySendMessage) return;
      let date = new Date();
      let random = getRandomStr();
      let msgRandomSecret = `${
        loginUserInfo.user_id
      }_${date.getTime()}_${random}`;
      let params = {
        to: store.chatStates.roomId,
        msg_type: MESSAGE_TYPE_ENUM.TEXT,
        msg_contents: readySendMessage,
        from_uid: loginUserInfo.user_id,
        belong_to_thread_id: store.chatStates.belongThreadId,
        reply_to_msg_id: store.chatStates.replyMessageInfo?.id || "",
        msg_random_secret: msgRandomSecret,
      };
      let waitConfirmMsg = {
        ...params,
        sendStatus: "wait_callback_confirm",
      };
      store.chatStates.messages.push(waitConfirmMsg);
      setReadySendMessage("");
      store.sendMessage(JSON.stringify(params)).then();
      store.scrollMessageDom();
      if (store.chatStates.replyMessageInfo) {
        store.chatStates.setReplyMessageInfo(null);
      }
      // setReadySendMessage("");
    },
    {
      wait: 500,
    }
  );
  const showBulkMessage = useMemo(() => {
    if (process.env.REACT_APP_NODE_ENV === "development") {
      return true;
    } else {
      return loginUserInfo && loginUserInfo.is_business_user;
    }
  }, []);

  const RenderMoreMessageBox = useCallback(() => {
    return (
      <div>
        <div className="more-send-message-type">
          <div className="message-type-item-box">
            <div
              className="message-type-item-img-box"
              onClick={() => {
                store.chatStates.setShowModal(true);
                store.chatStates.setShowModalType(
                  MODAL_TYPE_ENUM.CREATE_CONTRACT
                );
                store.chatStates.setPushMessageSelectedUsers([]);
                store.chatStates.setSearching(false);
                store.chatStates.setMoreMessageShow(false);
              }}
            >
              <img src={transferIcon} alt="" />
            </div>
            <p>Transfer</p>
          </div>
          <div className="message-type-item-box">
            <div
              className="message-type-item-img-box"
              onClick={async () => {
                store.chatStates.setShowModal(true);
                store.chatStates.setShowModalType(MODAL_TYPE_ENUM.SUDO_SWAP);
                store.chatStates.setMoreMessageShow(false);
              }}
            >
              <img
                src={sudoSwapIcon}
                style={{ width: "80%", height: "auto" }}
                alt=""
              />
            </div>
            <p>Sudoswap</p>
          </div>
          {showBulkMessage && (
            <div className="message-type-item-box">
              <div
                className="message-type-item-img-box"
                onClick={async () => {
                  store.chatStates.setShowModal(true);
                  store.chatStates.setShowModalType(
                    MODAL_TYPE_ENUM.BULK_MESSAGE
                  );
                  store.chatStates.setPushMessageSelectedUsers([]);
                  store.chatStates.setSearching(false);
                  store.chatStates.setMoreMessageShow(false);
                }}
              >
                <img
                  src={bulkMessageIcon}
                  style={{ width:'16px', height:"auto" }}
                  alt=""
                />
              </div>
              <p>Bulk messaging</p>
            </div>
          )}
        </div>
        <div className="twilio-page-footer-down-box">
          <img src={jinggaoIcon} alt="" />
          General smart contract support is coming soon
        </div>
      </div>
    );
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="chat-page-footer">
      <div className="twilio-page-footer-up-box">
        {/*{store.chatStates.roomMembers.length === 1 && (*/}
        <div className="send-more-box">
          <IonIcon
            className="send-more"
            slot="icon-only"
            icon={
              store.chatStates.moreMessageShow ? closeCircle : addCircleSharp
            }
            onClick={() => {
              if (store.chatStates.disableInput) return;
              let isShow = store.chatStates.moreMessageShow;
              store.chatStates.setMoreMessageShow(!isShow);
            }}
          />
        </div>
        {/*)}*/}

        <div className="modal-footer-box">
          <IonInput
            className="inputComment message-input"
            value={readySendMessage}
            disabled={
              store.chatStates.disableInput || store.chatStates.showLoading
            }
            placeholder="Write a message"
            onIonChange={(e) => {
              setReadySendMessage(xss(e.detail.value!));
            }}
            onIonFocus={() => {
              store.chatStates.setMoreMessageShow(false);
              store.chatStates.setSendButtonShow(true);
            }}
            onIonBlur={() => {
              store.chatStates.setMoreMessageShow(false);
              store.chatStates.setSendButtonShow(false);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                run();
              }
            }}
          />
          <img
            className="send-button-icon"
            src={sendMessageIcon}
            onClick={run}
            alt=""
          />
        </div>
      </div>
      {store.chatStates.replyMessageInfo && (
        <div className="footer-reply-message-box">
          <div>{store.chatStates.replyMessageInfo?.msg_contents || ""}</div>
          <img
            onClick={() => {
              store.chatStates.setReplyMessageInfo(null);
            }}
            src={replyMessageCloseIcon}
            alt=""
          />
        </div>
      )}
      {store.chatStates.moreMessageShow && <RenderMoreMessageBox />}
    </div>
  );
};

export default observer(ChatPageFooter);
