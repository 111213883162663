import React, { useEffect, useState } from "react";

import "./index.scss";
import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonItemSliding,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useStore } from "src/services/mobx/service";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { UserInfoInterface } from "../../../../services/api/apiInterface/chatInterface";
import * as ChatApi from "../../../../services/api/chat";
import { getUserAvatar, getUserNickName } from "../../../../constant/utils";

const userIcon = require("../../../../assert/svg/user.svg");

const RoomMembersModal: React.FC = observer(() => {
  const store = useStore();
  const history = useHistory();

  const [sendToNumber, setSendToNumber] = useState("");
  const [toUsers, setToUsers] = useState<UserInfoInterface[]>([]);
  const [searching, setSearching] = useState(false);
  const [scopedContacts, setScopedContacts] = useState<UserInfoInterface[]>([]);

  const delItemPreData = (data: UserInfoInterface) => {
    setToUsers(toUsers.filter((item) => item.user_id !== data.user_id));
  };

  const setPreUserData = (data: UserInfoInterface) => {
    // 判断是否在room中
    const member = store.chatStates.roomMembers.find(
      (item) => item.user_id === data.user_id
    );
    if (member) return;
    const user = toUsers.find((item) => item.user_id === data.user_id);

    if (user) {
      return;
    }
    setToUsers([...toUsers, data]);
  };

  const addUserToRoom = async () => {
    if (toUsers.length < 1) return;
    store.chatStates.setShowLoading(true);
    let userIds = toUsers.map(item => {
      return item.user_id
    })
    let addRes = await ChatApi.addMemberToRoom({
      room_id: store.chatStates.roomId,
      target_user_ids: userIds,
    });
    if (addRes.code !== 0) {
      store.chatStates.setShowLoading(false);
      store.chatStates.setErrorMessage(addRes.msg);
      store.chatStates.setShowErrorAlert(true);
      return;
    }
    setToUsers([]);
    setSearching(false);
    store.chatStates.setShowLoading(false);
    store.chatStates.setShowModal(false)
    store.chatStates.setShowModalType('');
    await store.chatStates.initRoomInfo(store.authStates.platform);

  };

  const delMember = async (memberId: string) => {
    store.chatStates.setShowLoading(true);
    const delRes = await ChatApi.delMemberFromRoom({
      room_id: store.chatStates.roomId,
      member_id: memberId,
    });

    if (delRes.code !== 0) {
      store.chatStates.setShowLoading(false);
      store.chatStates.setErrorMessage(delRes.msg);
      store.chatStates.setShowErrorAlert(true);
      return;
    }
    store.chatStates.setShowLoading(false);
    store.chatStates.setShowModal(false);
    store.chatStates.setShowModalType('');
    await store.chatStates.initRoomInfo(store.authStates.platform);
  };

  useEffect(() => {
    setScopedContacts(store.homeStates.contacts);
  }, []);

  return (
    <IonPage className="chat-room-members-modal-page">
      <IonHeader className="chat-room-members-modal-header">
        <IonToolbar className="chat-room-members-modal-header-toolbar">
          <IonTitle className="room-members-header-title">
            <p>{searching ? "New Group Chat" : "Room Members"}</p>
          </IonTitle>
          <IonButtons
            slot="start"
            className="ion-close-icon header-close-button"
          >
            <IonButton
              onClick={() => {
                store.chatStates.setShowModal(false)
                store.chatStates.setShowModalType('')
              }}
            >
              close
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" className="ion-close-icon header-close-button">
            <IonButton onClick={addUserToRoom}>invite</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="chat-room-members-modal-content">
        <IonList>
          <IonItem className="select-subscriber-item">
            <span className="to-text" style={{ marginRight: "16px" }}>
              To
            </span>
            <div className="pre-user-box">
              {toUsers.map((item, index) => {
                return (
                  <IonButton
                    className="user-button"
                    size="small"
                    color="light"
                    key={`${item.user_id}_${index}`}
                    onClick={() => {
                      delItemPreData(item);
                    }}
                  >
                    {getUserNickName(item, store.authStates.platform)}
                  </IonButton>
                );
              })}
              <IonInput
                className="select-subscriber-input"
                value={sendToNumber}
                onIonChange={(e) => {
                  let phone = e.detail.value!;
                  setSendToNumber(phone);
                  let itemContacts = store.homeStates.contacts.filter(
                    (user) => {
                      let username = getUserNickName(
                        user,
                        store.authStates.platform
                      );
                      if (
                        username !== "Unnamed" &&
                        username.indexOf(phone) !== -1
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  );
                  setScopedContacts(itemContacts);
                }}
                onIonFocus={() => {
                  setSearching(true);
                }}
                onIonBlur={() => {
                  setSendToNumber("");
                  setScopedContacts(store.homeStates.contacts);
                  // setSearching(false);
                }}
              />
            </div>
          </IonItem>
          <IonItem className="chat-room-members-title-item">
            <p>{searching ? "contacts" : "members"}</p>
          </IonItem>

          {searching
            ? scopedContacts.map((member: any, index) => (
                <IonItem
                  key={`contact_${index}`}
                  className="room-members-list-item"
                  onClick={() => {
                    setPreUserData(member);
                    setSearching(false);
                  }}
                >
                  <IonAvatar slot="start" className="member-list-avatar">
                    <img
                      src={
                        getUserAvatar(member, store.authStates.platform) ||
                        userIcon
                      }
                      alt=""
                    />
                  </IonAvatar>
                  <IonLabel className="member-body">
                    {getUserNickName(member, store.authStates.platform)}
                  </IonLabel>
                </IonItem>
              ))
            : store.chatStates.roomMembers.map((member, index) => (
                <IonItemSliding key={index}>
                  <IonItem className="room-members-list-item">
                    <IonAvatar slot="start" className="member-list-avatar">
                      <img
                        src={
                          getUserAvatar(member, store.authStates.platform) ||
                          userIcon
                        }
                        alt=""
                      />
                    </IonAvatar>
                    <IonLabel className="member-body">
                      {getUserNickName(member, store.authStates.platform)}
                    </IonLabel>
                  </IonItem>
                  {/*<IonItemOptions*/}
                  {/*  side="end"*/}
                  {/*  className="chat-list-item-delete-options"*/}
                  {/*>*/}
                  {/*  <IonItemOption*/}
                  {/*    onClick={() => {*/}
                  {/*      delMember(member.user_id);*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    {t("Delete")}*/}
                  {/*  </IonItemOption>*/}
                  {/*</IonItemOptions>*/}
                </IonItemSliding>
              ))}
        </IonList>
      </IonContent>
      <IonLoading
        isOpen={store.chatStates.showLoading}
        onDidDismiss={() => store.chatStates.setShowLoading(false)}
        message={"Loading"}
      />
      <IonAlert
        isOpen={store.chatStates.showErrorAlert}
        onDidDismiss={() => {
          store.chatStates.setShowErrorAlert(false);
          store.chatStates.setErrorMessage("");
        }}
        header={"Error"}
        message={store.chatStates.errorMessage}
        buttons={["Dismiss"]}
      />
    </IonPage>
  );
});
export default RoomMembersModal;
