import React, { useEffect, useState } from "react";
import { assetDataUtils } from "@0x/order-utils";

import "./index.scss";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useStore } from "src/services/mobx/service";
import { observer } from "mobx-react-lite";
import * as ChatApi from "../../../../services/api/chat";
import { closeOutline } from "ionicons/icons";
import { convertTimestamp, getAssetsData } from "../../../../utils/SudoSwap";
import { MESSAGE_TYPE_ENUM } from "../../../../constant/enum";
import {getUserInfoByJWT} from "../../../../constant/utils";

const Tokens = require("../../../../assert/tokens/tokens.json");
const sudoSwapIcon =require('../../../../assert/svg/sudoswapIcon.svg')
const SudoSwapModal: React.FC = observer(() => {
  const [tradeCode, setTradeCode] = useState("");
  const store = useStore();
  const submitTradeCode = async () => {
    store.chatStates.setShowLoading(true);
    const codeRes = await ChatApi.getSudoSwap({
      tradeCode: tradeCode,
    });
    if (codeRes.code === 0) {
      let tradeData = codeRes.data;
      let asset1List = assetDataUtils.decodeAssetDataOrThrow(
        tradeData.Order.takerAssetData
      );
      let asset2List = assetDataUtils.decodeAssetDataOrThrow(
        tradeData.Order.makerAssetData
      );
      let expiryDate = "";
      if (tradeData.Order.expirationTimeSeconds) {
        expiryDate = convertTimestamp(tradeData.Order.expirationTimeSeconds);
      }
      let asset1Data = await getAssetsData(asset1List);
      let asset2Data = await getAssetsData(asset2List);
      let jumpUrl = `https://sudoswap.xyz/#/swap/${tradeCode.substring(
        0,
        42
      )}/${tradeCode.substring(42)}`;
      let readySendMessageContents = {
        orderStatus: tradeData.Status,
        expiryDate,
        creatorAddress: tradeData.AccountId,
        recipientAddress:
          tradeData.Recipient === "0x0000000000000000000000000000000000000000"
            ? ""
            : tradeData.Recipient,
        jumpUrl,
        asset1Data,
        asset2Data,
      };
      await store.sendMessage(
        JSON.stringify({
          to: store.chatStates.roomId,
          msg_type: MESSAGE_TYPE_ENUM.SUDO_SWAP_CARD,
          msg_contents: readySendMessageContents,
          from_uid: getUserInfoByJWT().user_id,
          belong_to_thread_id: store.chatStates.belongThreadId,
          reply_to_msg_id: store.chatStates.replyMessageInfo?.id || "",
        })
      );
      store.chatStates.setShowModal(false);
      store.chatStates.setShowModalType("");

      store.chatStates.setShowLoading(false);
    }
    store.chatStates.setShowLoading(false);
  };
  useEffect(() => {}, []);
  return (
    <IonPage className="sudo-swap-modal-page">
      <IonHeader className="house-contract-modal-header">
        <IonToolbar>
          <div className="sudo-swap-header-title">
            <img src={sudoSwapIcon} alt=""/>
            Sudoswap
          </div>
          <IonButtons slot="end" className="ion-close-icon done-button">
            <IonButton
              onClick={() => {
                store.chatStates.setShowModal(false);
                store.chatStates.setShowModalType("");
              }}
            >
              <IonIcon
                style={{ color: "#000" }}
                slot="icon-only"
                icon={closeOutline}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="sudo-swap-modal-content">
        <div className="bind-platform-dialog">
          <div className="bind-platform-dialog-header">
            <h1 className="bind-platform-dialog-header-title">
              How to share a Sudoswap to chat
            </h1>
          </div>
          <div className="bind-platform-dialog-content">
            <div className="bind-platform-dialog-box">
              <div className="step-item-box">
                <div className="step-item-box-circle-box">1</div>
                <h1>Step 1</h1>
                <p>
                  Go to{" "}
                  <span
                      onClick={() => {
                        window.open("https://sudoswap.xyz/", "_blank");
                      }}
                      style={{
                        fontWeight: "500",
                        color: "#5d62e7",
                        cursor: "pointer",
                      }}
                  >
                      Sudoswap
                    </span>
                </p>
              </div>
              <div className="step-item-box">
                <div className="step-item-box-circle-box">2</div>
                <h1>Step 2</h1>
                <p>
                  Look for <span style={{ color:'#424242', fontWeight: '500' }}>“recent swaps”</span>, click a swap you would
                  like to share to chat.
                </p>
              </div>
              <div className="step-item-box">
                <div className="step-item-box-circle-box">3</div>
                <h1>Step 3</h1>
                <p>
                  Once the swap opened, look for “trade code” copy and paste
                  it on step 4.{" "}
                </p>
              </div>
            </div>
            <div className="step-item-box final-step-item-box">
              <div className="step-item-box-circle-box">4</div>
              <h1>Step 4</h1>
              <div className="step-item-box-input">
                <IonInput
                    className="inputComment"
                    value={tradeCode}
                    placeholder="Enter trade code"
                    onIonChange={(e) => {
                      setTradeCode(e.detail.value!);
                    }}
                />
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <IonFooter className="sudo-swap-modal-footer">
        <IonButton
            className="send-button"
            expand="block"
            onClick={submitTradeCode}
        >
          Send to chat
        </IonButton>
      </IonFooter>
    </IonPage>
  );
});
export default SudoSwapModal;
