import { action, makeAutoObservable, observable } from "mobx";
import { tokenMgr } from "../../axios/axios";
import {
  AUTH_STORE_KEY_ENUM,
  getPlatformStatusByCode,
  PLATFORM_ENUM,
} from "../../../constant/enum";
import * as ChatApi from "../../api/chat";

export default class AuthStore {
  // @observable auth:AuthState = defaultValue;
  @observable isAuthenticated: boolean = false;
  @observable sessionToken: string = "";
  @observable platform: PLATFORM_ENUM = PLATFORM_ENUM.TWITTER;
  @observable fromPage: string = "normal";
  @observable loginUserInfo: any = {
    user_id: 0,
  };
  @observable showLoading: boolean = false;
  @observable showErrorAlert: boolean = false;
  @observable errorMessage: string = "user not found";
  @observable backUrl = "";
  @observable inviteCodes: any[] = [];
  @observable isInvited: boolean = false;

  constructor() {
    makeAutoObservable(this); //even though this isn't required in some examples, this seems key line to making mobx work
  }

  @action setIsInvited() {
    this.isInvited = getPlatformStatusByCode(
      this.loginUserInfo.status,
      "invited"
    );
  }

  @action setAuthStateData(key: AUTH_STORE_KEY_ENUM, data: any) {
    switch (key) {
      case AUTH_STORE_KEY_ENUM.SHOW_LOADING:
        this.showLoading = data;
        break;
      case AUTH_STORE_KEY_ENUM.SHOW_ERROR_ALERT:
        this.showErrorAlert = data;
        break;
      case AUTH_STORE_KEY_ENUM.ERROR_MESSAGE:
        this.errorMessage = data;
        break;
      case AUTH_STORE_KEY_ENUM.BACK_URL:
        this.backUrl = data;
        break;
      case AUTH_STORE_KEY_ENUM.IS_AUTHENTICATED:
        this.isAuthenticated = data;
        break;
      case AUTH_STORE_KEY_ENUM.FROM_PAGE:
        this.fromPage = data;
        break;
      case AUTH_STORE_KEY_ENUM.LOGIN_USER_INFO:
        this.loginUserInfo = data;
        break;
    }
  }

  @action setSessionToken(token: string) {
    tokenMgr().setToken(token);
  }

  @action setPlatform(data: PLATFORM_ENUM) {
    this.platform = data;
  }

  @action
  async getMyInviteCodes() {
    const codesRes = await ChatApi.getMyInviteCodes();
    if (codesRes.code === 0) {
      if (codesRes.data) {
        this.inviteCodes = codesRes.data;
      } else {
        this.inviteCodes = [];
      }
    }
  }
}
