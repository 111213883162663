import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./index.scss";
import {
  IonAvatar,
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSegment,
  IonSegmentButton,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import { useStore } from "src/services/mobx/service";
import { observer } from "mobx-react-lite";
import { closeOutline } from "ionicons/icons";
import * as ChatApi from "../../../../services/api/chat";
import {
  getShortAddressByAddress,
  getUserAvatar,
  getUserNickName,
} from "../../../../constant/utils";
import { PLATFORM_ENUM } from "../../../../constant/enum";
import { useHistory } from "react-router-dom";
import copy from "copy-to-clipboard";
import { UserInfoInterface } from "../../../../services/api/apiInterface/chatInterface";

const userIcon = require("../../../../assert/svg/user.svg");
const sendMessageIcon = require("../../../../assert/svg/profileSendMessageIcon.svg");
const addContactIcon = require("../../../../assert/svg/addContactIcon.svg");
const copyIcon = require("../../../../assert/svg/copyIcon.svg");
const facebookIcon = require("../../../../assert/img/pre-facebook-icon.png");
const twitterIcon = require("../../../../assert/svg/twitterIcon.svg");
const openseaIcon = require("../../../../assert/svg/openseaIcon.svg");
const discordIcon = require("../../../../assert/svg/discordIcon.svg");
const insIcon = require("../../../../assert/img/pre-instagram-icon.png");
const nextIdIcon = require("../../../../assert/img/nextIdIcon.jpeg");
const profileWalletIcon = require("../../../../assert/svg/profileWalletIcon.svg");
const profileENSIcon = require("../../../../assert/svg/profileENSIcon.svg");
const profileDotBitIcon = require("../../../../assert/svg/profileDotBitIcon.svg");
const profileNameTagIcon = require("../../../../assert/svg/profileNameTagIcon.svg");
const isBusinessUserIcon = require("../../../../assert/svg/isBusinessUser.svg");

const accountsConfigs = [
  {
    platform: PLATFORM_ENUM.TWITTER,
    defaultIcon: twitterIcon,
    title: "Twitter",
  },
  {
    platform: PLATFORM_ENUM.OPENSEA,
    defaultIcon: openseaIcon,
    title: "OpenSea",
  },
  {
    platform: PLATFORM_ENUM.DISCORD,
    defaultIcon: discordIcon,
    title: "Discord",
  },
  {
    platform: PLATFORM_ENUM.FACEBOOK,
    defaultIcon: facebookIcon,
    title: "Facebook",
  },
  {
    platform: PLATFORM_ENUM.INSTAGRAM,
    defaultIcon: insIcon,
    title: "Instagram",
  },
];

interface IAppProps {
  userInfo: UserInfoInterface;
}
type dids = {
  nextId: string,
  ens: string,
  dotBit: string[],
  nameTag: string[],
}

const size = 30;
const MemberProfileModal: React.FC<IAppProps> = observer((props) => {
  const store = useStore();
  const history = useHistory();
  const [present] = useIonToast();
  const { userInfo } = props;
  const [segmentValue, setSegmentValue] = useState("0");
  const [nextUserPersonal, setNextUserPersonal] = useState("");
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState(false);
  const [userNFTs, setUserNFTs] = useState<any[]>([]);
  const [DIDs, setDIDs] = useState<dids>({
    nextId: "",
    ens: "",
    dotBit: [],
    nameTag: [],
  });

  const isContacts = useMemo(() => {
    return window.location.pathname === "/chat/home/contacts";
  }, []);
  const isHomePage = useMemo(() => {
    return window.location.pathname === "/chat/home/homepage";
  }, []);

  const queryDids = async () => {
    console.log("ready to search mask");
    let dids: dids = {
      nextId: "",
      ens: "",
      dotBit: [],
      nameTag: [],
    };
    // next id
    if (userInfo.twitter_username) {
      let nextUser = await ChatApi.getNextIdUserInfo({
        platform: PLATFORM_ENUM.TWITTER,
        username: userInfo.twitter_username,
      });
      if (nextUser.ids[0] && nextUser.ids[0].persona) {
        dids.nextId = getShortAddressByAddress(nextUser.ids[0].persona);
      }
    }

    const nameTagData = await ChatApi.getUserNameTag({
      user_id: userInfo.user_id,
      wallet_address: process.env.REACT_APP_NODE_ENV === 'development' ? "0x3a07908a0ac2e125dfd9bf5734b5f9309efb86dc" : userInfo.eth_wallet_address,
    });
    if (nameTagData.data && nameTagData.data.nametags && nameTagData.data.nametags.length > 0) {
      nameTagData.data.nametags.forEach((item: any) => {
        if (item.name) {
          dids.nameTag.push(item.name as string)
        }
      })
    }

    const dotBitsData = await ChatApi.getUserDotBits({
      user_id: userInfo.user_id,
      wallet_address: process.env.REACT_APP_NODE_ENV === 'development' ? "0x1d643fac9a463c9d544506006a6348c234da485f" : userInfo.eth_wallet_address,
    });
    if (dotBitsData.data && dotBitsData.data.result) {
      let result = dotBitsData.data.result
      if (result.data && result.data.account_list && result.data.account_list.length > 0) {
        result.data.account_list.forEach((item: any) => {
          if (item.account) {
            dids.dotBit.push(item.account as string)
          }
        })
      }
    }
    setDIDs(dids);
  };
  const getUserNFTs = async (page: number = 1) => {
    const nftData = await ChatApi.getUserNFTList({
      user_id: userInfo.user_id,
      wallet_address: process.env.REACT_APP_NODE_ENV === 'development' ? "0xa99a76dddbb9678bc33f39919bc76d279c680c89" : userInfo.eth_wallet_address,
      page: page,
      size,
    });
    if (nftData.data) {
      const total = nftData.data.tota;
      if (nftData.data.assets.length < size) {
        setIsInfiniteDisabled(true);
      }
      if (page === 1) {
        setUserNFTs(nftData.data.assets);
      } else {
        setUserNFTs(nftData.data.assets.concat(userNFTs));
      }
    }
  };

  const loadData = async (ev: any) => {
    let currentPage = (userNFTs.length / size).toFixed(0);
    let item = +currentPage + 1;
    await getUserNFTs(item);
    ev.target.complete();
  };

  useEffect(() => {
    const init = async () => {
      await queryDids();
      await getUserNFTs();
    };
    init();
  }, []);

  useIonViewWillEnter(() => {
    // 处理tab1白屏问题
    let errClassStr = "ion-page-invisible";
    let ele: any = document.querySelector(".member-profile-modal-page");
    if (ele && ele.className) {
      let str = ele.className;
      let index = str.indexOf(errClassStr);
      if (index > -1) {
        ele.className = str.replace(errClassStr, "");
      }
    }
  });

  const RenderWalletsList = useCallback(() => {
    return (
      <IonContent className="profile-wallets-list-content">
        <div className="profile-wallets-list">
          <img src={profileWalletIcon} className="profile-wallet-icon" />
          <div className="profile-item-label">
            <div className="profile-item-label-text">
              {getShortAddressByAddress(userInfo.eth_wallet_address)}
            </div>
            <img
              src={copyIcon}
              onClick={() => {
                let copyRes = copy(userInfo.eth_wallet_address);
                present("Copied", 1000);
              }}
              className="username-copy-icon"
              alt=""
            />
          </div>
        </div>
      </IonContent>
    );
  }, []);

  const RenderDidsList = useCallback(() => {
    return (
      <IonContent style={{ height: '300px' }} className="profile-wallets-list-content">
        <div className="profile-wallets-list">
          <img src={nextIdIcon} />
          <div className="profile-item-label">
            <div className="profile-item-label-title">Next.ID</div>
            <div className="profile-item-label-text">{DIDs.nextId}</div>
          </div>
        </div>
        <div className="profile-wallets-list">
          <img src={profileENSIcon} />
          <div className="profile-item-label">
            <div className="profile-item-label-title">ENS</div>
            {userInfo.ens_name && (
              <div className="profile-item-label-text">{userInfo.ens_name}</div>
            )}
          </div>
        </div>
        {DIDs.dotBit.length > 0 ? (
          DIDs.dotBit.map((item, index) => {
            return (
              <div className="profile-wallets-list" key={index}>
                <img src={profileDotBitIcon} />
                <div className="profile-item-label">
                  <div className="profile-item-label-title">.bit</div>
                  <div className="profile-item-label-text">{item}</div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="profile-wallets-list">
            <img src={profileDotBitIcon} />
            <div className="profile-item-label">
              <div className="profile-item-label-title">.bit</div>
            </div>
          </div>
        )}
        {DIDs.nameTag.length > 0 ? (
          DIDs.nameTag.map((item, index) => {
            return (
              <div className="profile-wallets-list" key={index}>
                <img src={profileNameTagIcon} />
                <div className="profile-item-label">
                  <div className="profile-item-label-title">Nametag</div>
                  <div className="profile-item-label-text">{`@${item}`}</div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="profile-wallets-list">
            <img src={profileNameTagIcon} />
            <div className="profile-item-label">
              <div className="profile-item-label-title">NameTag</div>
            </div>
          </div>
        )}
      </IonContent>
    );
  }, [DIDs]);

  const RenderAccountsList = useCallback(() => {
    return (
      <IonContent className="profile-wallets-list-content">
        {accountsConfigs.map((item, index) => {
          return (
            <div className="profile-wallets-list" key={index}>
              <img src={item.defaultIcon} />
              <div className="profile-item-label">
                <div className="profile-item-label-account-title">
                  {item.title}
                </div>
                <div className="profile-item-label-text">
                  {userInfo[`${item.platform}_username`]
                    ? "@" + userInfo[`${item.platform}_username`]
                    : "Coming Soon"}
                </div>
              </div>
            </div>
          );
        })}
      </IonContent>
    );
  }, [nextUserPersonal]);

  return (
    <IonPage className="member-profile-modal-page">
      <IonContent className="member-profile-modal-content">
        <div className="user-info-box">
          {!isHomePage && (
            <div className="profile-close-button-box">
              <IonButton
                className="profile-close-button"
                onClick={() => {
                  store.chatStates.setShowModal(false);
                  store.chatStates.setShowModalType("");
                }}
              >
                <IonIcon
                  style={{ color: "#000" }}
                  slot="icon-only"
                  icon={closeOutline}
                />
              </IonButton>
            </div>
          )}
          <div style={{ zIndex: 999 }} className="avatar-box">
            <img
              className="profilepage-avatar"
              src={
                getUserAvatar(userInfo, store.authStates.platform) || userIcon
              }
              alt=""
            />
          </div>
          <div className="username">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "32px",
                lineHeight: "32px",
              }}
            >
              @{getUserNickName(userInfo, store.authStates.platform)}
              {userInfo.is_business_user && (
                <img src={isBusinessUserIcon} alt="" />
              )}
            </div>
            <div className="user-box-wallet-box">
              <div className="user-wallet-toggle-box">
                {userInfo.ens_name
                  ? userInfo.ens_name
                  : getShortAddressByAddress(userInfo.eth_wallet_address)}
                <img
                  src={copyIcon}
                  onClick={(event) => {
                    let copyRes = copy(userInfo.eth_wallet_address);
                    present("Copied", 1000);
                  }}
                  className="username-copy-icon"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        {!isHomePage && (
          <div className="profile-audit-button-box">
            <IonButton
              disabled={store.chatStates.roomInfo.is_1v1 && !isContacts}
              className="footer-action-button"
              expand="block"
              onClick={async () => {
                const roomData = await ChatApi.getRooms({
                  user_id: userInfo.user_id,
                });
                if (roomData.data) {
                  store.chatStates.setShowModal(false);
                  store.chatStates.setShowModalType("");
                  store.resetChatStore();
                  let pathName = `/chat/chatWebPage?roomId=${roomData.data}`;
                  history.replace(pathName);
                  return;
                }
              }}
            >
              <img src={sendMessageIcon} alt="" />
              Send message
            </IonButton>
            {userInfo.is_business_user ? (
              <IonButton
                className="footer-action-button"
                expand="block"
                onClick={async () => {
                  const roomData = await ChatApi.getRooms({
                    user_id: userInfo.user_id,
                  });
                  if (roomData.data) {
                    present("Subscribe Success", 1000);
                    store.chatStates.setShowModal(false);
                    store.chatStates.setShowModalType("");
                  }
                }}
              >
                + Subscribe
              </IonButton>
            ) : !isContacts ? (
              <IonButton
                className="footer-action-button"
                expand="block"
                onClick={async () => {
                  const roomData = await ChatApi.getRooms({
                    user_id: userInfo.user_id,
                  });
                  if (roomData.data) {
                    present("Add contact success", 1000);
                    store.chatStates.setShowModal(false);
                    store.chatStates.setShowModalType("");
                  }
                }}
              >
                <img src={addContactIcon} alt="" />
                Add contact
              </IonButton>
            ) : null}
          </div>
        )}

        <IonSegment
          className="dashboard-content-segment"
          value={segmentValue}
          onIonChange={(e: any) => {
            setSegmentValue(e.detail.value);
          }}
        >
          <IonSegmentButton value="0">
            <IonLabel>Wallets</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="1">
            <IonLabel>DIDs</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="2">
            <IonLabel>NFTs</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="3">
            <IonLabel>Accounts</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <div className="segment-content-box">
          {segmentValue === "0" && <RenderWalletsList />}
          {segmentValue === "1" && <RenderDidsList />}
          {segmentValue === "2" && (
            <IonContent style={{ height: '300px' }} className="profile-nft-list-content">
              <div className="member-nft-item-box">
                {userNFTs.length > 0 &&
                  userNFTs.map((item, index) => {
                    return (
                      <div className="member-nft-item-card" key={index}>
                        <img src={item.nft.image} alt="" />
                      </div>
                    );
                  })}
              </div>
              <IonInfiniteScroll
                onIonInfinite={loadData}
                threshold="30px"
                disabled={isInfiniteDisabled}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data"
                />
              </IonInfiniteScroll>
            </IonContent>
          )}
          {segmentValue === "3" && <RenderAccountsList />}
        </div>
      </IonContent>
      {isHomePage && (
        <IonFooter className="house-super-home-footer">
          <IonButton
            className="logout-button"
            expand="block"
            onClick={() => {
              store.logout();
              history.replace("/chat/auth");
            }}
          >
            Logout
          </IonButton>
        </IonFooter>
      )}
    </IonPage>
  );
});
export default MemberProfileModal;
