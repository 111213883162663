import React, { useEffect } from "react";

import "./index.scss";
import {
  IonAlert,
  IonButton,
  IonContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useHistory, useLocation } from "react-router-dom";
import * as LoginApi from "../../services/api/login";
import * as ChatApi from "../../services/api/chat";
import { useStore } from "../../services/mobx/service";
import {
  AUTH_STORE_KEY_ENUM,
  getPlatformStatusByCode,
  PLATFORM_ENUM,
} from "../../constant/enum";
import {
  getEthAccount,
  getUserInfoByJWT,
  isInvited,
  isLogin,
  parseJwt,
} from "../../constant/utils";
import { LoginParams } from "../../services/api/apiInterface/loginInterface";
import moment from "moment";
import Web3 from "web3";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

const metaMaskFox = require("../../assert/svg/MetaMaskFox.svg");
const clickSignImg = require("../../assert/svg/clickSignImg.svg");
// @ts-ignore
const web3 = new Web3(window.ethereum);
const Auth: React.FC = observer(() => {
  const history = useHistory();
  const store = useStore();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const platform = urlSearch.get("platform");
  const fromPage = urlSearch.get("fromPage");
  let backUrlArr = window.location.search.split("callbackUrl");
  let callbackUrl = "";
  if (backUrlArr.length > 0 && backUrlArr[1]) {
    callbackUrl = backUrlArr[1].substring(1);
  }

  if (platform) {
    store.authStates.setPlatform(platform as PLATFORM_ENUM);
  }
  if (fromPage) {
    store.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.FROM_PAGE, fromPage);
  }
  const checkUserStatus = () => {
    let loginUserInfo = getUserInfoByJWT();
    if (
      ![PLATFORM_ENUM.TWITTER, PLATFORM_ENUM.DISCORD].includes(
        store.authStates.platform
      )
    ) {
      return true;
    }
    let checkRes = getPlatformStatusByCode(
      loginUserInfo.status,
      store.authStates.platform
    );
    if (!checkRes) {
      if (store.authStates.platform === PLATFORM_ENUM.TWITTER) {
        store.authStates.setAuthStateData(
          AUTH_STORE_KEY_ENUM.SHOW_LOADING,
          false
        );
        history.replace(
          `/chat/verifyTwitterPage?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`
        );
        // let url = `${window.location.origin}/chat/verifyTwitterPage?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`;
        // window.open(url, "_blank");
        return false;
      }
      if (store.authStates.platform === PLATFORM_ENUM.DISCORD) {
        store.authStates.setAuthStateData(
          AUTH_STORE_KEY_ENUM.SHOW_LOADING,
          false
        );
        let url = `${window.location.origin}/chat/verifyDiscordPage/?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`;
        history.replace(
          `/chat/verifyDiscordPage?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`
        );
        // window.open(url, "_blank");
        return false;
      }
    }
    return checkRes;
  };
  const signMetamask = async () => {
    // @ts-ignore
    let ethAccount = await getEthAccount();
    if (!ethAccount.address) {
      store.authStates.setAuthStateData(
        AUTH_STORE_KEY_ENUM.SHOW_LOADING,
        false
      );
      return false;
    }
    let address = ethAccount.address;
    if (store.authStates.platform === PLATFORM_ENUM.OPENSEA) {
      await ChatApi.register({
        platform: PLATFORM_ENUM.OPENSEA,
        user_name: address,
      });
    }

    let randomSecret = await LoginApi.getLoginRandomSecret({
      wallet_address: address,
    });
    if (randomSecret.code !== 0) {
      await ChatApi.register({
        platform: "metamask",
        user_name: address,
      });
      randomSecret = await LoginApi.getLoginRandomSecret({
        wallet_address: address,
      });
    }
    if (randomSecret.data) {
      const msg = `0x${Buffer.from(randomSecret.data, "utf8").toString("hex")}`;
      let signContent = `SwapChat wants you to sign in with your Ethereum account:
${address}
for SwapChat login
URI: https://chat.web3messaging.online/
Nonce: ${msg}
Issued At: ${moment().utc().local().format("DD/MM/YYYY HH:mm")}`;
      const res = await web3.eth.personal
        .sign(signContent, address, "swapchat")
        .catch((e) => {
          store.authStates.setAuthStateData(
            AUTH_STORE_KEY_ENUM.SHOW_LOADING,
            false
          );
        });
      if (res) {
        await loginAfterSign(res, address, signContent);
      } else {
        store.authStates.setAuthStateData(
          AUTH_STORE_KEY_ENUM.SHOW_LOADING,
          false
        );
      }
    } else {
      store.authStates.setAuthStateData(
        AUTH_STORE_KEY_ENUM.SHOW_LOADING,
        false
      );
    }
  };
  useEffect(() => {
    if (isLogin()) {
      if (history.location.pathname === "/chat/auth") {
        console.log("准备跳转到chats   现在是auth页面");
        history.replace("/chat/home/chats");
      }
    }
  }, []);
  //
  // useEffect(() => {
  //   let accessToken = tokenMgr().getToken();
  //   if (accessToken) {
  //     let jwtToken = accessToken.substring(7);
  //     let tokenArr = jwtToken.split(".");
  //     let tokenObj = parseJwt(tokenArr[1]);
  //     let accessExpiredAt = tokenObj.access_expired_at * 1000;
  //     let refreshExpiredAt = tokenObj.refresh_expired_at;
  //     if (moment.utc().isAfter(moment(accessExpiredAt).utc())) {
  //       console.log('token过期')
  //       store.logout();
  //       history.replace(`/chat/auth?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`);
  //     } else {
  //       //正常
  //       console.log("准备跳转到chats   现在是auth页面");
  //       history.replace("/chat/home/chats");
  //     }
  //   } else {
  //     console.log('token不存在')
  //     store.logout();
  //     history.replace(`/chat/auth?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`);
  //   }
  // }, []);
  const loginAfterSign = async (
    res: string,
    address: string,
    signContent: string
  ) => {
    let loginParams: LoginParams = {
      wallet_address: address,
      signature: res,
      login_random_secret: signContent,
    };
    if (urlSearch.get("twitterUserAvatar")) {
      loginParams.user_avatar = decodeURIComponent(
        urlSearch.get("twitterUserAvatar") || ""
      );
    }
    const loginRes = await LoginApi.login(loginParams);
    if (loginRes.code === 0) {
      store.authStates.setAuthStateData(
        AUTH_STORE_KEY_ENUM.IS_AUTHENTICATED,
        true
      );
      store.authStates.setSessionToken(loginRes.data.access_token);
      // 登录成功设置登录信息
      let tokenArr = loginRes.data.access_token.split(".");
      if (!tokenArr || !tokenArr[1]) {
        store.chatStates.setErrorMessage("user info error");
        store.authStates.setAuthStateData(
          AUTH_STORE_KEY_ENUM.SHOW_LOADING,
          false
        );
        store.chatStates.setShowErrorAlert(true);
        return false;
      }
      let userInfo = parseJwt(tokenArr[1]);
      store.authStates.setAuthStateData(
        AUTH_STORE_KEY_ENUM.LOGIN_USER_INFO,
        userInfo
      );
      if (userInfo.eth_wallet_address) {
        document.title = `SwapChat - ${userInfo.eth_wallet_address}`;
      }
      store.authStates.setIsInvited();
      if (!isInvited()) {
        store.authStates.setAuthStateData(
          AUTH_STORE_KEY_ENUM.SHOW_LOADING,
          false
        );
        history.replace("/chat/verifyCodePage");
        return;
      }
      // 校验用户是否认证当前平台
      const checkStatusRes = checkUserStatus();
      if (checkStatusRes) {
        store.authStates.setAuthStateData(
          AUTH_STORE_KEY_ENUM.SHOW_LOADING,
          false
        );
        if (callbackUrl) {
          history.replace(callbackUrl);
        } else {
          if (store.authStates.backUrl) {
            let backUrl = store.authStates.backUrl;
            store.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.BACK_URL, "");
            history.replace(backUrl);
            return;
          } else {
            history.replace("/chat/home");
            return;
          }
        }
      }
    } else {
      store.authStates.setAuthStateData(
        AUTH_STORE_KEY_ENUM.SHOW_LOADING,
        false
      );
    }
  };

  const walletConnectSign = async (
    address: string,
    connector: WalletConnect
  ) => {
    let randomSecret = await LoginApi.getLoginRandomSecret({
      wallet_address: address,
    });
    if (randomSecret.code !== 0) {
      await ChatApi.register({
        platform: "metamask",
        user_name: address,
      });
      randomSecret = await LoginApi.getLoginRandomSecret({
        wallet_address: address,
      });
    }
    const msg = `0x${Buffer.from(randomSecret.data, "utf8").toString("hex")}`;
    let signContent = `SwapChat wants you to sign in with your Ethereum account:
${address}
for SwapChat login
URI: https://chat.web3messaging.online/
Nonce: ${msg}
Issued At: ${moment().utc().local().format("DD/MM/YYYY HH:mm")}`;
    let params = [web3.utils.utf8ToHex(signContent), address];
    try {
      const res = await connector.signPersonalMessage(params).catch((error) => {
        // Error returned when rejected
        console.error(error, "error");
      });

      await loginAfterSign(res, address, signContent);
    } catch (e) {
      console.log(e, "e");
    }
  };

  const initWalletConnect = async () => {
    localStorage.removeItem("walletconnect");
    // Create a connector
    const connector = new WalletConnect({
      bridge: "https://bridge.walletconnect.org", // Required
      qrcodeModal: QRCodeModal,
    });

    // Check if connection is already established
    if (!connector.connected) {
      // create new session
      connector.createSession();
    } else {
      // 已经登录成功
      await walletConnectSign(connector.accounts[0], connector);
    }

    // Subscribe to connection events
    connector.on("connect", async (error, payload) => {
      if (error) {
        throw error;
      }
      // Get provided accounts and chainId
      const { accounts, chainId } = payload.params[0];
      // 已经登录成功
      await walletConnectSign(accounts[0], connector);
    });

    connector.on("disconnect", (error, payload) => {
      if (error) {
        throw error;
      }

      // Delete connector
    });
  }


  return (
    <IonPage className="house-chan-auth-page">
      <IonContent className="house-chan-auth-content">
        <div className="auth-auth-content">
          <div className="auth-auth-content-text">
            <p className="up-text">Welcome to SwapChat</p>
            <p className="down-text">
              Let's get started with your decentralized social trading trip now!
            </p>
            <p className="step-text">Step1: Connect Wallet</p>
          </div>
          <div className="button-box">
            <IonButton
              className="fox-button"
              expand="block"
              disabled={store.authStates.fromPage === "popup"}
              onClick={() => {
                store.authStates.setAuthStateData(
                  AUTH_STORE_KEY_ENUM.SHOW_LOADING,
                  true
                );
                setTimeout(signMetamask, 1000);
              }}
            >
              <img src={metaMaskFox} alt="" />
              MetaMask
            </IonButton>
          </div>
          <div className="button-box">
            <IonButton
              className="fox-button"
              expand="block"
              disabled={store.authStates.fromPage === "popup"}
              onClick={() => {
                setTimeout(initWalletConnect, 1000);
              }}
            >
              <img
                style={{ width: "30px" }}
                src="https://docs.walletconnect.com/img/walletconnect-logo.svg"
                alt=""
              />
              WalletConnect
            </IonButton>
          </div>
        </div>
        {store.authStates.fromPage === "popup" && (
          <div className="auth-popup-content">
            <img src={clickSignImg} alt="" />
          </div>
        )}
        <IonAlert
          isOpen={store.authStates.showErrorAlert}
          onDidDismiss={() => {
            store.chatStates.setShowErrorAlert(false);
            store.chatStates.setErrorMessage("");
          }}
          header={"Error"}
          message={store.chatStates.errorMessage}
          buttons={["Dismiss"]}
        />

        <IonLoading
          isOpen={store.authStates.showLoading}
          onDidDismiss={() =>
            store.authStates.setAuthStateData(
              AUTH_STORE_KEY_ENUM.SHOW_LOADING,
              false
            )
          }
          message={"Loading"}
        />
      </IonContent>
    </IonPage>
  );
});
export default Auth;
