import React, { useCallback, useEffect } from "react";

import "./index.scss";
import {
  IonAvatar,
  IonContent,
  IonHeader,
  IonItem,
  IonItemSliding,
  IonLabel,
  IonList,
  IonPage,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useHistory, Redirect, Route } from "react-router-dom";
import { useStore } from "../../../services/mobx/service";
import ContactsPageSkeleton from "../../../components/Skeletons/ContactsPageSkeleton/ContactsPageSkeleton";
import {getUserAvatar, getUserNickName, isInvited} from "../../../constant/utils";
import { MODAL_TYPE_ENUM } from "../../../constant/enum";
import MemberProfileModal from "../../ChatWebPage/components/MemberProfileModal";

const userIcon = require("../../../assert/svg/user.svg");
/**
 *  粉丝和主播聊天
 */
const Contacts: React.FC = observer(() => {
  const history = useHistory();
  const store = useStore();
  useEffect(() => {
    const init = async () => {
      if (!isInvited()) {
        console.log("从联系人列表进入r认证code页面");
        history.replace("/chat/verifyCodePage");
        return;
      }
      store.chatStates.setShowModalType('');
      store.chatStates.setShowModal(false);
      store.homeStates.setShowLoading(true);
      await store.homeStates.getContacts(1);
      store.homeStates.setShowLoading(false);
    };
    init();
  }, []);

  const RenderContactList = useCallback(() => {
    if (store.homeStates.contacts.length <= 0) {
      return null;
    }
    return (
      <IonList>
        {store.homeStates.contacts.map((contact: any, index) => (
          <IonItemSliding key={index}>
            <IonItem
              className="chat-list-item"
              onClick={async () => {
                store.chatStates.setMemberProfileInfo(contact);
                store.chatStates.setShowModal(true);
                store.chatStates.setShowModalType(
                  MODAL_TYPE_ENUM.MEMBER_PROFILE
                );
              }}
            >
              <IonAvatar slot="start" className="message-list-avatar">
                <img
                  src={
                    getUserAvatar(contact, store.authStates.platform) ||
                    userIcon
                  }
                  alt=""
                />
              </IonAvatar>
              <IonLabel className="message-body">
                {getUserNickName(contact, store.authStates.platform)}
              </IonLabel>
            </IonItem>
          </IonItemSliding>
        ))}
      </IonList>
    );
  }, []);
  return (
    <>
      {store.homeStates.showLoading ? (
        <ContactsPageSkeleton />
      ) : (
        <IonPage className="house-super-contacts-page">
          <IonHeader className="house-super-contacts-page-header">
            <p>Contacts</p>
          </IonHeader>
          <IonContent className="house-super-contacts-page-content">
            {store.chatStates.showModal &&
            store.chatStates.showModalType ===
              MODAL_TYPE_ENUM.MEMBER_PROFILE ? (
              <MemberProfileModal userInfo={store.chatStates.memberProfileInfo} />
            ) : (
              <RenderContactList />
            )}
          </IonContent>
        </IonPage>
      )}
    </>
  );
});
export default Contacts;
