import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonModal,
  IonPage,
  IonPopover,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useStore } from "src/services/mobx/service";
import { observer } from "mobx-react-lite";
import * as ChatApi from "../../services/api/chat";
import {
  AUTH_STORE_KEY_ENUM,
  CONTRACT_STATUS_ENUM,
  getPlatformStatusByCode,
  MESSAGE_TYPE_ENUM,
  MODAL_TYPE_ENUM,
  PLATFORM_ENUM,
} from "../../constant/enum";
import { arrowBackOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import RoomMembersModal from "./components/RoomMembersModal";
import {
  getHref,
  getShortAddressByAddress,
  getSudoAssetType,
  getUserAvatar,
  getUserInfoByJWT,
  getUserNickName,
} from "../../constant/utils";
import MemberProfileModal from "./components/MemberProfileModal";
import ChatPageFooter from "../../components/ChatPageFooter";
import { logEvent } from "../../utils/analytics";
import SudoSwapModal from "./components/SudoSwapModal";
import BulkMessageModal from "./components/BulkMessageModal";
import {
  GetRoomsParams,
  UserInfoInterface,
} from "../../services/api/apiInterface/chatInterface";
import moment from "moment";

const userIcon = require("../../assert/svg/user.svg");
const allThreadIcon = require("../../assert/svg/all-thread.svg");
const transferIcon = require("../../assert/svg/transferIcon.svg");
const usersIcon = require("../../assert/svg/users.svg");
const ethLogo = require("../../assert/svg/ethLogo.svg");
const replyIcon = require("../../assert/svg/replyIcon.svg");
const newReplyIcon = require("../../assert/svg/newReplyIcon.svg");
const replyInThreadIcon = require("../../assert/svg/replyInThreadIcon.svg");
const replyInThreadToolPng = require("../../assert/svg/replyInThreadTool.png");
const replyToolPng = require("../../assert/svg/ReplyTool.png");
const threadIcon = require("../../assert/svg/threadIcon.svg");
const nftnoImg = require("../../assert/svg/nftnoImg.svg");
const msgLoadingIcon = require("../../assert/svg/msgLoading.svg");
const spacesIcon = require("../../assert/img/spacesIcon.png");

const ChatWebPage: React.FC = observer(() => {
  const history = useHistory();
  const [threadsPage, setThreadsPage] = useState(1);
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState(false);
  const store = useStore();
  const urlSearch = new URLSearchParams(window.location.search);
  const platform = urlSearch.get("platform");
  const fromPage = urlSearch.get("fromPage");
  const roomId = urlSearch.get("roomId");
  const accessToken = urlSearch.get("accessToken")
  const threadMsgId = urlSearch.get("threadMsgId");
  const messagePageSize = 500;
  if (platform) {
    store.authStates.setPlatform(platform as PLATFORM_ENUM);
  }
  if (fromPage) {
    store.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.FROM_PAGE, fromPage);
  }
  if (accessToken) {
    store.authStates.setAuthStateData(
        AUTH_STORE_KEY_ENUM.IS_AUTHENTICATED,
        true
    );
    store.authStates.setSessionToken(accessToken);
  }
  if (roomId) {
    if (threadMsgId) {
      store.chatStates.setBelongThreadId(threadMsgId);
      store.chatStates.setBelongMessageInfo(
          threadMsgId,
          store.authStates.platform
      ).then()
    }
    store.chatStates.setRoomId(roomId);
  }

  const loginUserInfo = getUserInfoByJWT();

  const RenderSysMessageNotify = useCallback((props) => {
    const { message } = props;

    if (message.msg_type === MESSAGE_TYPE_ENUM.CONTRACT_CONFIRMED) {
      return (
        <p className="system-message-notify">
          {loginUserInfo.user_id === message.from_uid ? (
            <span>you confirmed contract</span>
          ) : (
            <span>your contract has been confirmed!</span>
          )}
        </p>
      );
    }

    if (message.msg_type === MESSAGE_TYPE_ENUM.CONTRACT_REJECTED) {
      return (
        <p className="system-message-notify">
          {loginUserInfo.user_id === message.from_uid ? (
            <span>you rejected contract</span>
          ) : (
            <span>your contract has been rejected</span>
          )}
        </p>
      );
    }

    return null;
  }, []);

  const RenderMessage = useCallback((props) => {
    const { message } = props;
    if (message.msg_type === MESSAGE_TYPE_ENUM.TEXT) {
      if (message.belong_to_thread_id && !store.chatStates.belongThreadId) {
        return null;
      } else {
        return (
          <div
            className="message-contents-text"
            dangerouslySetInnerHTML={{ __html: getHref(message.msg_contents) }}
          />
        );
      }
    }

    if (message.msg_type === MESSAGE_TYPE_ENUM.CONTRACT_CREATED) {
      let redEnvelopeClass = "red-envelope";
      if (loginUserInfo.user_id === message.from_uid) {
        redEnvelopeClass += " red-envelope-right";
      } else {
        redEnvelopeClass += " red-envelope-left";
      }
      if (message.msg_contents.status) {
        redEnvelopeClass += ` message-${message.msg_contents.status}`;
      }
      return (
        <div
          className={redEnvelopeClass}
          onClick={() => {
            if (!message) return;
            if (message.msg_contents.status !== CONTRACT_STATUS_ENUM.CREATED)
              return;
            if (message.from_uid === loginUserInfo.user_id) return;
            store.chatStates.setCurrentContract(message);
            store.chatStates.setShowConfirmPopover(true);
          }}
        >
          <div className="red-envelope-up-box">
            <div className="red-envelope-icon">💰</div>
            <div className="red-envelope-title">
              <p>{message.msg_contents.title}</p>
              {message.msg_contents?.status ===
              CONTRACT_STATUS_ENUM.CREATED ? null : (
                <p>{message.msg_contents.status}</p>
              )}
            </div>
          </div>
          <div className="red-envelope-remark">{` by house chan`}</div>
        </div>
      );
    }

    if (message.msg_type === MESSAGE_TYPE_ENUM.SUDO_SWAP_CARD) {
      const messageContents = message.msg_contents;
      let redEnvelopeClass = "sudo-swap-message-card ";
      if (loginUserInfo.user_id === message.from_uid) {
        redEnvelopeClass += " sudo-swap-message-card-right";
      } else {
        redEnvelopeClass += " sudo-swap-message-card-left";
      }
      if (message.msg_contents.status) {
        redEnvelopeClass += ` message-${message.msg_contents.status}`;
      }
      return (
        <div className={redEnvelopeClass}>
          <div className="sudo-swap-card-up-box">
            <div className="sudo-swap-card-up-box-title">
              <img
                src="https://sudoswap.xyz/assets/vaporwave/logo.png"
                alt=""
              />
              <div>SudoSwap</div>
            </div>
            <div className="sudo-swap-nft-cards-box">
              <div className="sudo-swap-left-nft-card">
                <div className="sudo-swap-nft-item-card">
                  <div className="sudo-swap-nft-item-img-box">
                    {Number(messageContents.asset1Data[0].type) === 1 ? (
                      <div className="sudo-swap-nft-item-eth-logo-box">
                        <img src={ethLogo} alt="" />
                      </div>
                    ) : (
                      <img
                        src={messageContents.asset1Data[0].imgURL || ethLogo}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="sudo-swap-nft-item-info-box">
                    <div>{messageContents.asset1Data[0].name}</div>
                    {messageContents.asset1Data[0].id && (
                      <div>
                        <b>ID:</b> {messageContents.asset1Data[0].id}
                      </div>
                    )}
                    <div>
                      <b>Amount:</b> {messageContents.asset1Data[0].amount}
                    </div>
                    <div
                      className={
                        "info-box-type-" +
                        getSudoAssetType(
                          Number(messageContents.asset2Data[0].type)
                        )
                      }
                    >
                      <b>Type:</b>{" "}
                      {getSudoAssetType(
                        Number(messageContents.asset1Data[0].type)
                      )}
                    </div>
                  </div>
                </div>
                {messageContents.asset1Data.length > 1 && (
                  <div
                    className="load-more-sudo-swap-nft"
                    onClick={() => {
                      if (messageContents.jumpUrl) {
                        window.open(messageContents.jumpUrl, "_blank");
                      }
                    }}
                  >
                    {messageContents.asset1Data.length - 1} more ...
                  </div>
                )}
              </div>
              <div className="sudo-swap-middle-transfer-box">
                <div className="sudo-swap-middle-transfer-img-box">
                  <img src={transferIcon} alt="" />
                </div>
              </div>
              <div className="sudo-swap-left-nft-card sudo-swap-right-nft-card">
                <div className="sudo-swap-nft-item-card">
                  <div className="sudo-swap-nft-item-img-box">
                    {Number(messageContents.asset2Data[0].type) === 1 ? (
                      <div className="sudo-swap-nft-item-eth-logo-box">
                        <img src={ethLogo} alt="" />
                      </div>
                    ) : (
                      <img src={messageContents.asset2Data[0].imgURL} alt="" />
                    )}
                  </div>
                  <div className="sudo-swap-nft-item-info-box">
                    <div>{messageContents.asset2Data[0].name}</div>
                    {messageContents.asset2Data[0].id && (
                      <div>
                        <b>ID:</b> {messageContents.asset2Data[0].id}
                      </div>
                    )}
                    <div>
                      <b>Amount:</b> {messageContents.asset2Data[0].amount}
                    </div>
                    <div
                      className={
                        "info-box-type-" +
                        getSudoAssetType(
                          Number(messageContents.asset2Data[0].type)
                        )
                      }
                    >
                      <b>Type:</b>{" "}
                      {getSudoAssetType(
                        Number(messageContents.asset2Data[0].type)
                      )}
                    </div>
                  </div>
                </div>
                {messageContents.asset2Data.length > 1 && (
                  <div
                    className="load-more-sudo-swap-nft"
                    onClick={() => {
                      if (messageContents.jumpUrl) {
                        window.open(messageContents.jumpUrl, "_blank");
                      }
                    }}
                  >
                    {messageContents.asset2Data.length - 1} more ...
                  </div>
                )}
              </div>
            </div>
            <div className="contract-key-value-item">
              <div className="contract-key-box">Initiator</div>
              <div className="contract-value-box">
                <div>
                  {getShortAddressByAddress(messageContents.creatorAddress) ||
                    ""}
                </div>
              </div>
            </div>
            <div className="contract-key-value-item">
              <div className="contract-key-box">Recipient</div>
              <div className="contract-value-box">
                <div>
                  {messageContents.recipientAddress
                    ? getShortAddressByAddress(messageContents.recipientAddress)
                    : "AnyOne"}
                </div>
              </div>
            </div>
            {messageContents.expiryDate && (
              <div className="contract-key-value-item">
                <div className="contract-key-box">Expires on</div>
                <div className="contract-value-box">
                  {messageContents.expiryDate}
                </div>
              </div>
            )}
            <div className="red-envelope-button-box">
              <IonButton
                className="red-envelope-view-detail-button"
                onClick={() => {
                  if (messageContents.jumpUrl) {
                    window.open(messageContents.jumpUrl, "_blank");
                  }
                }}
              >
                Go to Sudoswap
              </IonButton>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }, []);

  const RenderMessageAvatar = useCallback((props) => {
    const { message } = props;
    let loginUserInfo = getUserInfoByJWT();
    let userInfo = store.chatStates.roomMembers.find(
      (item) => item.user_id === message.from_uid
    );
    if (message.from_uid === loginUserInfo.user_id) {
      userInfo = loginUserInfo;
    }
    return (
      <div className="message-user-title-box">
        <div
          className="message-user-info-box"
          onClick={() => {
            if (userInfo) {
              if (userInfo.user_id !== loginUserInfo.user_id) {
                store.chatStates.setMemberProfileInfo(userInfo);
                store.chatStates.setShowModal(true);
                store.chatStates.setShowModalType(
                  MODAL_TYPE_ENUM.MEMBER_PROFILE
                );
              }
            }
          }}
        >
          <img
            src={
              userInfo
                ? getUserAvatar(userInfo, store.authStates.platform) || userIcon
                : userIcon
            }
            alt=""
          />
          <div className="message-username">
            {getUserNickName(
              userInfo as UserInfoInterface,
              store.authStates.platform
            )}
          </div>
          {message.created_at > 0 && (
            <div className="message-create-at">
              {moment
                .utc(message.created_at / 1000000)
                .local()
                .format("MM/DD HH:mm")}
            </div>
          )}
        </div>
        <div className="message-other-icon"></div>
      </div>
    );
  }, []);

  const RenderMessageReplyThread = useCallback((props) => {
    const { message } = props;
    if (message.subThreadMessageCount <= 0) {
      return null;
    }

    return (
      <div className="message-reply-thread-box">
        <div className="message-users-img">
          <img
            src={message.subThreadLastMessageInfo.user_avatar || userIcon}
            alt=""
          />
        </div>
        <div
          className="reply-thread-message-count"
          onClick={() => {
            openSubRoom(message.id);
          }}
        >
          {message.subThreadMessageCount == 1
            ? `${message.subThreadMessageCount} reply`
            : `${message.subThreadMessageCount} replies`}
        </div>
        {message.created_at > 0 && (
          <div className="reply-thread-last-time">
            {message.subThreadLastMessageInfo.msg_time}
          </div>
        )}
      </div>
    );
  }, []);

  const RenderMessageFuncEle = useCallback((props) => {
    const { message } = props;
    return (
      <div className="message-func-box">
        <div className="message-func-icon-list">
          {/*<div>👍</div>*/}
          {/*<div>🚀</div>*/}
          {/*<div>🤣</div>*/}
          {/*<div>😡</div>*/}
          {!store.chatStates.belongThreadId && (
            <div className="reply-in-thread-icon-box">
              <img
                src={replyInThreadIcon}
                onClick={async () => {
                  if (message.belong_to_thread_id) {
                    console.log("不能创建三级thread");
                    return;
                  }
                  console.log("go to sub room");
                  if (!message.is_thread) {
                    console.log("create sub room");
                    createThreads(message.id).then((res) => {
                      openSubRoom(message.id);
                    });
                  } else {
                    await openSubRoom(message.id);
                  }
                }}
                alt=""
              />
              <div className="reply-in-thread-tool-box">
                <img src={replyInThreadToolPng} alt="" />
              </div>
            </div>
          )}

          <div className="reply-icon-box">
            <img
              src={newReplyIcon}
              onClick={() => {
                store.chatStates.setReplyMessageInfo(message);
              }}
              alt=""
            />
            <div className="reply-tool-box">
              <img src={replyToolPng} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const RenderBelongMessageInfo = useCallback(() => {
    return (
      <div className="opensea-thread-belong-message-box">
        <div className="opensea-thread-belong-message">
          <img
            src={
              store.chatStates.belongMessageInfo.opensea_item_image_url ||
              nftnoImg
            }
            alt=""
          />
          <div className="opensea-info-item">
            <div>
              {store.chatStates.belongMessageInfo.opensea_item_name || ""}
            </div>
            <div>
              <span>Created by </span>
              <span>
                {store.chatStates.openseaBelongNFTItem?.creator || ""}
              </span>
            </div>
            <div>
              <span>Sale ends </span>
              <span>
                {store.chatStates.openseaBelongNFTItem?.lastSaleData || ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const RenderUserNoInviteCard = useCallback(() => {
    let toUserInfo = store.chatStates.roomMembers[0];
    if (!toUserInfo) return null;
    if (!toUserInfo.rest_id) return null;
    // const jumpUrl = `https://twitter.com/messages/compose?recipient_id=${toUserInfo.rest_id}&text=hello I'm swapchat`
    const jumpUrl = `https://twitter.com/messages/compose?recipient_id=${toUserInfo.rest_id}`;
    let twitterParams = `@${toUserInfo.twitter_username}`;
    const postTweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      twitterParams
    )}`;
    return (
      <div className="user-not-invite-box">
        <div>👋🏼</div>
        <div>
          @{getUserNickName(toUserInfo, store.authStates.platform)} is not on
          SwapChat yet
        </div>
        <div>Do you want to invite him to join?</div>
        <div className="invite-btns">
          <a href={jumpUrl} target="_blank">
            + Invite with twitter dm
          </a>
          <a href={postTweetUrl} target="_blank">
            + Invite with twitter post
          </a>
        </div>
      </div>
    );
  }, []);

  const createThreads = async (msgId: string) => {
    store.chatStates.setShowLoading(true);
    const createThreadsRes = await ChatApi.createThreads({
      msg_id: msgId,
    });
    if (createThreadsRes.code !== 0) {
      store.chatStates.setShowLoading(false);
      store.chatStates.setErrorMessage(createThreadsRes.msg);
      store.chatStates.setShowErrorAlert(true);
      return;
    }
    await store.chatStates.setMessages(1, store.authStates.platform);
    await store.chatStates.getThreads();

    store.chatStates.setShowLoading(false);
  };

  const openSubRoom = async (messageId: string) => {
    store.chatStates.setShowLoading(true);
    store.chatStates.setBelongThreadId(messageId);
    await store.chatStates.setBelongMessageInfo(
      messageId,
      store.authStates.platform
    );
    await store.chatStates.setMessages(1, store.authStates.platform);
    store.chatStates.setOpenMenuShow(false);
    store.chatStates.setShowLoading(false);
  };

  const backToRoom = async () => {
    if (!store.chatStates.belongThreadId) return;
    store.chatStates.setShowLoading(true);
    store.chatStates.setBelongThreadId("");
    store.chatStates.resetBelongMessageInfo();
    await store.chatStates.setMessages(1, store.authStates.platform);
    history.replace(`/chat/chatWebPage?roomId=${roomId}`)
    store.chatStates.setShowLoading(false);
  };

  const checkUserStatus = (platform: PLATFORM_ENUM) => {
    let loginUser = getUserInfoByJWT();
    if (
      ![PLATFORM_ENUM.TWITTER, PLATFORM_ENUM.DISCORD].includes(
        store.authStates.platform
      )
    ) {
      return true;
    }
    let checkRes = getPlatformStatusByCode(loginUser.status, platform);
    if (!checkRes) {
      if (platform === PLATFORM_ENUM.TWITTER) {
        history.replace(
          `/chat/verifyTwitterPage?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`
        );
        // let url = `${window.location.origin}/chat/verifyTwitterPage?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`;
        // window.open(url, "_blank");
        return false;
      }
      if (platform === PLATFORM_ENUM.DISCORD) {
        let url = `${window.location.origin}/chat/verifyDiscordPage/?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`;
        history.replace(
          `/chat/verifyDiscordPage?fromPage=${store.authStates.fromPage}&platform=${store.authStates.platform}`
        );
        // window.open(url, "_blank");
        return false;
      }
    }
    return checkRes;
  };

  const init = async () => {
    const userHash = urlSearch.get("userHash");
    const collectionName = urlSearch.get("collectionName");
    const itemContractAddress = urlSearch.get("itemContractAddress");
    const itemTokenId = urlSearch.get("itemTokenId");
    let openseaAccountAddress = urlSearch.get("openseaAccountAddress");
    const openseaAccountUsername = urlSearch.get("openseaAccountUsername");
    const twitterSpaceId = urlSearch.get("spaceId");
    const twitterFriendAvatar = urlSearch.get("twitterFriendAvatar");
    const linkPlatform = urlSearch.get("linkPlatform");
    const collSlug = urlSearch.get("collSlug");
    // todo 对方未注册时，给对方发送私信
    const friendTwitterId = urlSearch.get("friendTwitterId");
    store.resetChatStore();
    let fromUserInfo = getUserInfoByJWT();
    if (!fromUserInfo) {
      let backUrl = window.location.pathname + window.location.search;
      store.authStates.setAuthStateData(AUTH_STORE_KEY_ENUM.BACK_URL, backUrl);
      console.log("鉴权失败？，跳转到auth");
      let backParams = window.location.search;
      if (backParams.length > 0) {
        backParams += `&callbackUrl=${backUrl}`;
      }
      history.replace(`/chat/auth${backParams}`);
      return;
    }
    let isInvited = getPlatformStatusByCode(fromUserInfo.status, "invited");
    if (!isInvited) {
      console.log("从聊天页面跳转到认证code");
      history.replace("/chat/verifyCodePage");
      return;
    }
    let checkRes = checkUserStatus(store.authStates.platform);
    if (!checkRes) {
      console.log("验证平台错误");
      return false;
    }
    console.log("react init");
    store.chatStates.setShowLoading(true);
    await store.setWs();
    store.chatStates.setDisableInput(false);
    logEvent("chat-page", "visit");
    if (!roomId) {
      let pageRoomId = "";
      // twitter discord平台进入，传参userHash
      if (userHash) {
        const users = decodeURIComponent(userHash).split("@@");
        const fromUserName = users[0].replace("@", "#");
        // 校验当前登录用户和历史用户
        // if (
        //   fromUserInfo[`${store.authStates.platform}_username`] !== fromUserName
        // ) {
        //   // 校验失败  重新授权登录
        //   store.logout();
        //   history.replace(`/chat/auth${window.location.search}`);
        //   return;
        // }
        store.authStates.setAuthStateData(
          AUTH_STORE_KEY_ENUM.LOGIN_USER_INFO,
          fromUserInfo
        );
        if (twitterSpaceId) {
          const roomData = await ChatApi.getRooms({
            is_twitter_space: true,
            space_id: twitterSpaceId,
          });
          if (roomData.code !== 0) {
            store.chatStates.setShowLoading(false);
            store.chatStates.setErrorMessage(roomData.msg);
            store.chatStates.setShowErrorAlert(true);
            return;
          }
          if (roomData.data) {
            pageRoomId = roomData.data;
          }
        } else {
          const toUserName = users[1].replace("@", "#");
          await store.chatStates.setToUserInfo(
            toUserName,
            store.authStates.platform
          );
          let getRoomParams: GetRoomsParams = {
            user_id: store.chatStates.toUserInfo.user_id,
          };
          if (twitterFriendAvatar) {
            getRoomParams.target_user_avatar =
              decodeURIComponent(twitterFriendAvatar);
          }
          const roomData = await ChatApi.getRooms(getRoomParams);
          if (roomData.code !== 0) {
            store.chatStates.setShowLoading(false);
            store.chatStates.setErrorMessage(roomData.msg);
            store.chatStates.setShowErrorAlert(true);
            return;
          }
          if (roomData.data) {
            pageRoomId = roomData.data;
          }
        }
      }
      // 从opensea进入 创建一对一聊天
      if (store.authStates.platform === PLATFORM_ENUM.OPENSEA) {
        if (openseaAccountUsername) {
          let openseaAddress = await ChatApi.getOpenseaAddressByUsername(
            openseaAccountUsername
          );
          if (openseaAddress.code !== 0) {
            store.chatStates.setShowLoading(false);
            store.chatStates.setErrorMessage(openseaAddress.msg);
            store.chatStates.setShowErrorAlert(true);
            return;
          }
          if (openseaAddress.data && openseaAddress.data.account) {
            openseaAccountAddress = openseaAddress.data.account.address;
          }
        }
        if (openseaAccountAddress) {
          await store.chatStates.setToUserInfo(
            openseaAccountAddress,
            store.authStates.platform
          );
          const roomData = await ChatApi.getRooms({
            user_id: store.chatStates.toUserInfo.user_id,
          });

          if (roomData.code !== 0) {
            store.chatStates.setShowLoading(false);
            store.chatStates.setErrorMessage(roomData.msg);
            store.chatStates.setShowErrorAlert(true);
            return;
          }
          if (roomData.data) {
            pageRoomId = roomData.data;
          }
        }
      }

      // 从opensea进入创建多人聊天室
      if (collectionName) {
        const roomData = await ChatApi.getRooms({
          is_opensea_coll: true,
          opensea_coll_slug: collectionName,
        });
        if (roomData.code !== 0) {
          store.chatStates.setShowLoading(false);
          store.chatStates.setErrorMessage(roomData.msg);
          store.chatStates.setShowErrorAlert(true);
          return;
        }
        if (roomData.data) {
          pageRoomId = roomData.data;
        }
      }

      // 从opensea nft item进入， 创建群聊，创建thread
      if (itemContractAddress && itemTokenId && linkPlatform && collSlug) {
        const createThreadsRes = await ChatApi.createThreads({
          is_opensea_item_thread: true,
          opensea_item_token_id: itemTokenId,
          opensea_item_contract_address: itemContractAddress,
          chain_name: linkPlatform,
          opensea_coll_slug: collSlug,
        });

        if (createThreadsRes.code !== 0) {
          store.chatStates.setShowLoading(false);
          store.chatStates.setErrorMessage(createThreadsRes.msg);
          store.chatStates.setShowErrorAlert(true);
          return;
        }

        if (createThreadsRes.data.room_id) {
          pageRoomId = createThreadsRes.data.room_id;
        }
        if (createThreadsRes.data.msg_id) {
          store.chatStates.setBelongThreadId(createThreadsRes.data.msg_id);
          await store.chatStates.setBelongMessageInfo(
            createThreadsRes.data.msg_id,
            store.authStates.platform
          );
        }
      }
      store.chatStates.setRoomId(pageRoomId);
    }
    // await store.setWs();
    await store.chatStates.initRoomInfo(store.authStates.platform);
    await store.chatStates.setMessages(1, store.authStates.platform);
    await store.chatStates.getThreads();
    await store.homeStates.getContacts();
    store.chatStates.setShowLoading(false);
    store.scrollMessageDom();
  };

  const loadData = async (ev: any) => {
    let currentPage = (
      store.chatStates.messages.length / messagePageSize
    ).toFixed(0);
    let newPage = +currentPage + 1;
    await store.chatStates.setMessages(newPage, store.authStates.platform);
    if (store.chatStates.getMessageCount < messagePageSize) {
      setIsInfiniteDisabled(true);
    }
    const dom: any = document.getElementById("msg_start");
    if (dom) {
      dom.scrollIntoView();
    }
    ev.target.complete();
  };
  useEffect(() => {
    init();
  }, [roomId, platform, fromPage]);

  return (
    <IonPage className="twilio-page">
      <IonHeader className="twilio-page-header">
        <IonToolbar className="twilio-page-header-toolbar">
          <IonButtons slot="start" className="ion-close-icon">
            <IonButton
              onClick={() => {
                if (store.chatStates.belongThreadId) {
                  backToRoom();
                } else {
                  store.resetChatStore();
                  history.replace("/chat/home/chats");
                }
              }}
            >
              <IonIcon
                style={{ color: "#1F2128" }}
                slot="icon-only"
                icon={arrowBackOutline}
              />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" className="ion-close-icon end-btns-box">
            <img
              src={allThreadIcon}
              onClick={() => {
                let show = store.chatStates.openMenuShow;
                store.chatStates.setOpenMenuShow(!show);
              }}
              alt=""
            />
            All threads
            {!store.chatStates.roomInfo.is_1v1 && (
              <img
                src={usersIcon}
                onClick={() => {
                  store.chatStates.setShowModal(true);
                  store.chatStates.setShowModalType(
                    MODAL_TYPE_ENUM.ROOM_MEMBERS
                  );
                }}
                alt=""
              />
            )}
          </IonButtons>
          <div className="twilio-header-title">
            {store.chatStates.showLoading ? (
              "Loading"
            ) : store.chatStates.belongThreadId ? (
              <div className="thread-header-title">
                <div>Thread</div>
                <div>
                  #{store.chatStates.belongMessageInfo?.msg_contents || ""}{" "}
                </div>
              </div>
            ) : (
              <>
                <img
                  src={ store.chatStates.roomInfo.is_twitter_space ? spacesIcon : store.chatStates.roomInfo.room_avatar || userIcon}
                  onClick={() => {
                    if (store.chatStates.roomInfo.is_1v1 && store.chatStates.roomMembers[0]) {
                      store.chatStates.setMemberProfileInfo(store.chatStates.roomMembers[0]);
                      store.chatStates.setShowModal(true);
                      store.chatStates.setShowModalType(
                          MODAL_TYPE_ENUM.MEMBER_PROFILE
                      );
                    }
                  }}
                  style={{
                    borderRadius: store.chatStates.roomInfo.is_1v1
                      ? "50%"
                      : "10px",
                  }}
                  alt=""
                />
                <IonTitle>{store.chatStates.roomInfo.room_name}</IonTitle>
              </>
            )}
          </div>
        </IonToolbar>
      </IonHeader>
      {store.chatStates.belongMessageInfo && (
        <div className="thread-belong-message-box">
          {store.chatStates.belongMessageInfo.opensea_item_token_id ? (
            <RenderBelongMessageInfo />
          ) : (
            <>
              <RenderMessageAvatar
                message={store.chatStates.belongMessageInfo}
              />
              <RenderMessage message={store.chatStates.belongMessageInfo} />
            </>
          )}
          <div className="thread-message-footer">
            <div>
              {store.chatStates.messages.length > 0
                ? store.chatStates.messages.length === 1
                  ? `${store.chatStates.messages.length} reply`
                  : `${store.chatStates.messages.length} replies`
                : "0 reply"}
            </div>
            <div />
          </div>
        </div>
      )}
      <IonContent
        className={
          store.chatStates.belongThreadId
            ? "twilio-page-content ion-padding thread-page-content"
            : "twilio-page-content ion-padding"
        }
        onClick={() => {
          store.chatStates.setMoreMessageShow(false);
        }}
      >
        <IonInfiniteScroll
          onIonInfinite={loadData}
          threshold="50px"
          position="top"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more message"
          />
        </IonInfiniteScroll>
        <div id="msg_start" style={{ height: "0", overflow: "hidden" }} />
        {store.chatStates.openMenuShow && (
          <div className="threads-list-box">
            <div className="threads-list">
              <div className="message-box">
                {store.chatStates.threads.length > 0 &&
                  store.chatStates.threads.map((thread: any, index: number) => {
                    return (
                      <div className="thread-box" key={index}>
                        <img src={threadIcon} alt="" />
                        <div
                          onClick={() => {
                            openSubRoom(thread.id);
                          }}
                          className="thread-message-content"
                        >
                          {thread.msg_contents || ""}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {store.chatStates.getThreadsCount >= 20 && (
              <div
                className="load-more-threads"
                onClick={async () => {
                  store.chatStates.setShowLoading(true);
                  let threadPage = threadsPage;
                  let newPage = ++threadPage;
                  setThreadsPage(newPage);
                  await store.chatStates.getThreads(newPage);
                  store.chatStates.setShowLoading(false);
                }}
              >Load More Threads...
              </div>
            )}
          </div>
        )}
        <div className="messages-content">
          <RenderUserNoInviteCard />
          {store.chatStates.messages.length > 0 &&
            store.chatStates.messages.map((message: any, index: number) => {
              return (
                <div key={`test_${index}`}>
                  <RenderSysMessageNotify message={message} />
                  <div className="message-box">
                    {message.msg_type === MESSAGE_TYPE_ENUM.TEXT && (
                      <RenderMessageFuncEle message={message} />
                    )}
                    <RenderMessageAvatar message={message} />
                    <div className="message-body-content-box">
                      <RenderMessage message={message} />
                      {message.sendStatus && (
                        <div className="message-status-box">
                          {message.sendStatus === "wait_callback_confirm" ? (
                            <img src={msgLoadingIcon} alt="" />
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>

                    {message.replyMsgInfo && (
                      <div
                        className="message-content-reply-box"
                        style={{
                          marginBottom: message.is_thread ? "20px" : "0",
                          marginTop: message.is_thread ? "-15px" : "5px",
                        }}
                      >
                        <img src={replyIcon} alt="" />
                        <div>
                          {message.replyMsgInfo.user_name}:{" "}
                          {message.replyMsgInfo.msg_contents}
                        </div>
                      </div>
                    )}
                    {message.is_thread && (
                      <RenderMessageReplyThread message={message} />
                    )}
                  </div>
                </div>
              );
            })}
          <div id="msg_end" style={{ height: "0", overflow: "hidden" }} />
        </div>
      </IonContent>

      <IonFooter className="twilio-page-footer ion-no-border">
        <ChatPageFooter />
      </IonFooter>
      <IonLoading
        isOpen={store.chatStates.showLoading}
        onDidDismiss={() => store.chatStates.setShowLoading(false)}
        message={"Loading"}
      />
      <IonAlert
        isOpen={store.chatStates.showErrorAlert}
        onDidDismiss={() => {
          store.chatStates.setShowErrorAlert(false);
          store.chatStates.setErrorMessage("");
        }}
        header={"Error"}
        message={store.chatStates.errorMessage}
        buttons={["Dismiss"]}
      />
      <IonPopover
        //@ts-ignore
        cssClass="confirm-contract-popover"
        isOpen={store.chatStates.showConfirmPopover}
        onDidDismiss={() => store.chatStates.setShowConfirmPopover(false)}
      >
        <p>Confirm Contract</p>
        <p>
          {store.chatStates.currentContract?.msg_contents?.title ||
            "God bless you"}
        </p>
        <IonButtons className="popover-footer-buttons">
          <IonButton
            onClick={async () => {
              console.log("close nft");
              // await confirmContract(MESSAGE_TYPE_ENUM.CONTRACT_REJECTED);
            }}
          >
            Reject
          </IonButton>
          <IonButton
            onClick={async () => {
              console.log("confirm nft");
              console.log(
                store.chatStates.currentContract,
                "store.chatStates.currentContract"
              );
              console.log(
                store.chatStates.currentContract.msg_contents,
                "store.chatStates.currentContract.msg_contents"
              );
              if (
                store.chatStates.currentContract &&
                store.chatStates.currentContract.msg_contents.tx_id
              ) {
                let url = `${window.location.origin}/chat/NftSwapPage?roomId=${store.chatStates.roomId}&contractId=${store.chatStates.currentContract.msg_contents.tx_id}`;
                window.open(url, "_blank");
              }
            }}
          >
            Confirm
          </IonButton>
        </IonButtons>
      </IonPopover>

      <IonModal
        isOpen={store.chatStates.showModal}
        //@ts-ignore
        cssClass="house-chan-red-envelopes-modal"
        onDidDismiss={() => {
          store.chatStates.setShowModal(false);
          store.chatStates.setShowModalType("");
        }}
      >
        {/*用户列表*/}
        {store.chatStates.showModalType === MODAL_TYPE_ENUM.ROOM_MEMBERS && (
          <RoomMembersModal />
        )}
        {/*个人主页*/}
        {store.chatStates.showModalType === MODAL_TYPE_ENUM.MEMBER_PROFILE && (
          <MemberProfileModal userInfo={store.chatStates.memberProfileInfo} />
        )}
        {/*sudoswap（第三方交易平台）*/}
        {store.chatStates.showModalType === MODAL_TYPE_ENUM.SUDO_SWAP && (
          <SudoSwapModal />
        )}
        {/*bulk message（消息推送）*/}
        {store.chatStates.showModalType === MODAL_TYPE_ENUM.BULK_MESSAGE && (
          <BulkMessageModal />
        )}
      </IonModal>
    </IonPage>
  );
});
export default ChatWebPage;
