import React, { useCallback, useEffect, useMemo, useState } from "react";

import "./index.scss";
import { IonAlert, IonButton, IonInput, IonPage } from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useStore } from "../../services/mobx/service";
import * as LoginApi from "../../services/api/login";
import { AUTH_STORE_KEY_ENUM, PLATFORM_ENUM } from "../../constant/enum";
import {
  fromHex,
  getEthAccount, getUrlParamsByString, getUserInfoByJWT,
  toBase64,
} from "../../constant/utils";
import xxhash from "xxhash-wasm";
import * as ChatApi from "../../services/api/chat";

const clickHereLine = require("../../assert/img/clickHereLine.png");
const Web3 = require("web3");
/**
 *  粉丝和主播聊天
 */
const VerifyTwitterPage: React.FC = observer(() => {
  const history = useHistory();
  const store = useStore();
  const [twitterUrl, setTwitterUrl] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [jumpUrl, setJumpUrl] = useState("");

  const submitTwitterUrl = async () => {
    setDisableButton(true);
    if (!twitterUrl) return;
    let res = await LoginApi.verifyTwitterAccount(twitterUrl);
    // const res = { code: 0 };
    if (res.code === 0) {
      store.authStates.setAuthStateData(
        AUTH_STORE_KEY_ENUM.SHOW_LOADING,
        false
      );
      // 刷新token
      store.authStates.setSessionToken(res.data.access_token);
      let userInfo = getUserInfoByJWT();
      if (userInfo.eth_wallet_address) {
        document.title = `SwapChat - ${userInfo.eth_wallet_address}`;
      }
      if (store.authStates.backUrl) {
        history.replace(store.authStates.backUrl);
      } else {
        history.replace("/chat/home");
      }
    } else {
      store.authStates.setAuthStateData(
        AUTH_STORE_KEY_ENUM.SHOW_LOADING,
        false
      );
      store.authStates.setAuthStateData(
        AUTH_STORE_KEY_ENUM.ERROR_MESSAGE,
        "Verify Twitter Error"
      );
      store.authStates.setAuthStateData(
        AUTH_STORE_KEY_ENUM.SHOW_ERROR_ALERT,
        true
      );
      return;
    }
  };
  const walletAddress = useMemo(() => {
    let userInfo = getUserInfoByJWT()
    if (userInfo && userInfo.eth_wallet_address) {
      return userInfo.eth_wallet_address
    } else {
      return ''
    }
  }, []);

  useEffect(() => {
    if (walletAddress) {
      postTweet().then()
    }
  }, []);


  const postTweet = async () => {
    let address = "0x0000000";
    let userInfo = getUserInfoByJWT()
    if (userInfo.eth_wallet_address) {
      address = userInfo.eth_wallet_address
    } else {
      let ethAccount = await getEthAccount();
      if (ethAccount.address) {
        address = ethAccount.address;
      }
    }
    let nextSig = "";
    let backUrl = store.authStates.backUrl;
    let twitterUserName = ''
    if (backUrl) {
      let params = getUrlParamsByString(backUrl)
      if (params.userHash) {
        const users = decodeURIComponent(params.userHash).split("@@");
        twitterUserName = users[0].replace("@", "#");
      }
    }
    // 查询是否有nextinfo
    if (twitterUserName) {
      let nextUser = await ChatApi.getNextIdUserInfo({
        platform: PLATFORM_ENUM.TWITTER,
        username: twitterUserName
      })
      if (nextUser.ids[0] && nextUser.ids[0].persona) {
        /// 已经有过nextid
      } else {
        //@ts-ignore
        const web3 = new Web3(window.ethereum);
        const msg = web3.utils.sha3("hello");
        const sign = await web3.eth.personal.sign(msg, address);
        const pubKeyData = await ChatApi.sigToPubkey({
          sig: sign,
          msg: msg,
        });
        if (pubKeyData.data) {
          const pubKey = pubKeyData.data;
          // 开始认证next.id
          let signPayload = await ChatApi.getNextIdSignPayload({
            identity: twitterUserName,
            platform: PLATFORM_ENUM.TWITTER,
            action: "create",
            public_key: "0x04" + pubKey.substring(2),
          });
          if (signPayload && signPayload.sign_payload) {
            const payloadSign = await web3.eth.personal.sign(
                signPayload.sign_payload,
                address
            );
            let nextIdSig = toBase64(fromHex(payloadSign))
            nextSig = `Sig: ${nextIdSig}`
          }
        }
      }
    }
    const XXHash = await xxhash();
    let twitterParams = `I am verifying my Twitter for @SwapChatNFT and @NextDotID, my verify code is: ${XXHash.h64ToString(
      address.toLocaleLowerCase()
    )} Install https://www.web3messaging.online/publicity/ to start your social trading trip. 
    `;
    if (nextSig) {
      twitterParams += nextSig;
    }

    let url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      twitterParams
    )}`;
    setJumpUrl(url);
  };

  return (
    <IonPage className="house-chat-bind-platform-page">
      {submitSuccess ? (
        <div className="bind-platform-dialog">
          <div className="bind-success-img-box">
            <img src={clickHereLine} alt="" />
          </div>

          <div className="bind-success-text-box">
            Verify Twitter account success
          </div>
          <div className="bind-success-text-box">click x to start chat</div>
        </div>
      ) : (
        <div className="bind-platform-dialog">
          <div className="bind-platform-dialog-header">
            <h1 className="bind-platform-dialog-header-title">
              Verify your Twitter account
            </h1>
          </div>
          <div className="bind-platform-dialog-content">
            <div className="bind-platform-dialog-box">
              <div className="step-item-box">
                <div className="step-item-box-circle-box">1</div>
                <h1>Step 1</h1>
                <p>Click the button below to post a tweet. </p>
                <div className="step-item-box-button">
                  {jumpUrl ? (
                    <div className="submit-url-a-button">
                      <a href={jumpUrl} target="_blank">
                        Post a tweet
                      </a>
                    </div>
                  ) : (
                    <IonButton
                      className="submit-url-button"
                      expand="block"
                      onClick={() => {
                        setTimeout(postTweet, 1000)
                      }}
                      disabled={!!twitterUrl}
                    >
                      Connect Wallet
                    </IonButton>
                  )}
                </div>
              </div>
              <div className="step-item-box">
                <div className="step-item-box-circle-box">2</div>
                <h1>Step 2</h1>
                <p>Copy and paste the tweet URL in below.</p>
                <div className="step-item-box-input">
                  <IonInput
                    type="text"
                    placeholder="Enter the tweet address here"
                    className="bind-platform-input"
                    value={twitterUrl}
                    onIonChange={(e) => {
                      setDisableButton(false);
                      setTwitterUrl(e.detail.value!);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="step-item-box final-step-item-box">
              <div className="step-item-box-circle-box">3</div>
              <h1>Step 3</h1>
              <p>Hit the submit button once you pasted the URL.</p>
              <div className="step-item-box-button">
                <IonButton
                  className="submit-url-button"
                  expand="block"
                  disabled={disableButton}
                  onClick={submitTwitterUrl}
                >
                  Submit
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      )}
      <IonAlert
        isOpen={store.authStates.showErrorAlert}
        onDidDismiss={() =>
          store.authStates.setAuthStateData(
            AUTH_STORE_KEY_ENUM.SHOW_ERROR_ALERT,
            false
          )
        }
        header={"Error"}
        message={store.authStates.errorMessage}
        buttons={["Dismiss"]}
      />
    </IonPage>
  );
});
export default VerifyTwitterPage;
