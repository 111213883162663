import {
  roomRes,
  UserInfoInterface,
} from "../services/api/apiInterface/chatInterface";
import {
  getPlatformStatusByCode,
  MESSAGE_TYPE_ENUM,
  PLATFORM_ENUM,
} from "./enum";
import { tokenMgr } from "../services/axios/axios";
import { Convert } from "pvtsutils";
import moment from "moment";
import Web3 from "web3";
import { API_ROOT_URL } from "./index";
import * as ChatApi from "../services/api/chat";
import detectEthereumProvider from "@metamask/detect-provider";
// @ts-ignore
const web3 = new Web3(window.ethereum);

export function isMobile() {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent)
}

export function getUserAvatar(
  userInfo: UserInfoInterface,
  platform: PLATFORM_ENUM
) {
  let platforms = [
    PLATFORM_ENUM.TWITTER,
    PLATFORM_ENUM.OPENSEA,
    PLATFORM_ENUM.DISCORD,
  ];
  let userAvatar: string = "";
  if (isMobile() && platform === PLATFORM_ENUM.TWITTER) {
    return `${API_ROOT_URL}/avatars/${userInfo.user_id}`;
  }

  if (userInfo[`${platform}_avatar`]) {
    userAvatar = userInfo[`${platform}_avatar`] || "";
  } else {
    let findPlatform = platforms.find((item) => !!userInfo[`${item}_avatar`]);
    if (findPlatform) {
      userAvatar = userInfo[`${findPlatform}_avatar`] || "";
    }
  }
  return userAvatar;
}

export function getUserNickName(
  userInfo: UserInfoInterface,
  platform: PLATFORM_ENUM
) {
  if (!userInfo) return "Unnamed";
  if (userInfo[`${platform}_username`]) {
    return userInfo[`${platform}_username`];
  }
  // if (userInfo.ens_name && userInfo.ens_name.length > 0) {
  //   return userInfo.ens_name;
  // }
  let platforms = [
    PLATFORM_ENUM.TWITTER,
    PLATFORM_ENUM.OPENSEA,
    PLATFORM_ENUM.DISCORD,
  ];
  let userName: string = "";
  let findPlatform = platforms.find((item) => !!userInfo[`${item}_username`]);
  if (findPlatform) {
    userName = userInfo[`${findPlatform}_username`] || "";
  }
  if (!userName) {
    userName = getShortAddressByAddress(userInfo.eth_wallet_address);
  }
  return userName;
}

export function getUserInfoByJWT() {
  let accessToken = tokenMgr().getToken();
  if (accessToken) {
    let jwtToken = accessToken.substring(7);
    let tokenArr = jwtToken.split(".");
    return parseJwt(tokenArr[1]);
  }
  return null;
}

export function getAccessToken() {
  return tokenMgr().getToken().substring(7);
}

export function getRoomNameAndAvatarByRoom(
  roomItem: roomRes,
  platform: PLATFORM_ENUM
) {
  let myRoomObj = {
    room_name: "",
    room_avatar: "",
  };

  if (roomItem.name) {
    myRoomObj.room_name = roomItem.name;
  } else {
    if (roomItem.is_1v1) {
      myRoomObj.room_name = roomItem.members[0]
        ? getUserNickName(roomItem.members[0], platform)
        : "Unnamed";
    } else {
      myRoomObj.room_name = getUserNickName(getUserInfoByJWT(), platform);
      roomItem.members.forEach((member: any) => {
        let memberName = getUserNickName(member, platform);
        myRoomObj.room_name += `, ${memberName}`;
      });
    }
  }

  if (roomItem.opensea_coll_cover) {
    myRoomObj.room_avatar = roomItem.opensea_coll_cover;
  } else {
    let userInfo = roomItem.is_1v1 ? roomItem.members[0] : roomItem.creator;
    myRoomObj.room_avatar = userInfo ? getUserAvatar(userInfo, platform) : "";
  }
  return myRoomObj;
}

export async function getEthAccount() {
  const provider = await detectEthereumProvider()
  let res = {
    address: "",
    balance: 0,
    shortAddress: "",
  };
  let accounts = []
  // @ts-ignore
  let isSafePal = provider?.isSafePal;
  // @ts-ignore
  let isMetaMask = provider.isMetaMask;

  if (isSafePal) {
    //@ts-ignore
    try {
      accounts = await web3.eth.requestAccounts();
    } catch (e) {
      console.log(e);
    }
  }
  if (isMetaMask && isMobile()) {
    // @ts-ignore
    accounts = await provider.request({
      method: "eth_requestAccounts",
    });
  }

  if (accounts.length <=0) {
    // 网页
    // @ts-ignore
    const permissionRes = await web3.currentProvider
      // @ts-ignore
      .request({
        method: "wallet_requestPermissions",
        params: [{ eth_accounts: {} }],
      })
      .catch((e: any) => {
        console.log(e);
      });
    if (!permissionRes) return res;
    // @ts-ignore
    accounts = await provider.request({
      method: "eth_requestAccounts",
    });
  }
  if (accounts && accounts.length > 0) {
    res.address = accounts[0];
    let strLength = accounts[0].length;
    res.shortAddress =
        accounts[0].substring(0, 5) +
        "..." +
        accounts[0].substring(strLength - 4, strLength);
  }
  return res;
}

export function getShortAddressByAddress(address: string) {
  let strLength = address.length;
  return (
    address.substring(0, 5) +
    "..." +
    address.substring(strLength - 4, strLength)
  );
}

export function getSudoAssetType(index: number) {
  if (index == 1) {
    return "ERC20";
  }
  if (index == 2) {
    return "ERC721";
  }
  if (index == 3) {
    return "ERC1155";
  }
}

export function parseJwt(str: string) {
  return JSON.parse(
    decodeURIComponent(
      escape(window.atob(str.replace(/-/g, "+").replace(/_/g, "/")))
    )
  );
}

export function getRandomStr() {
  let arr = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  var str = "";
  for (let i = 0; i < 6; i++) {
    var num = Math.round(Math.random() * 61);
    str += arr[num];
  }
  return str;
}

export function fromHex(x: string) {
  if (x.startsWith("0x")) x = x.slice(2);
  // return Buffer.from(x, 'hex')

  return new Uint8Array(Convert.FromHex(x));
}

export function toBase64(x: BufferSource) {
  return Convert.ToBase64(x);
}

export function getUrlParamsByString(url: string) {
  let params: any = {};
  let urls = url.split("?");
  let arr = urls[1].split("&");
  for (let i = 0, l = arr.length; i < l; i++) {
    let a = arr[i].split("=");
    params[a[0]] = a[1];
  }
  return params;
}

export function isLogin(): boolean {
  let accessToken = tokenMgr().getToken();
  if (!accessToken) return false;
  let jwtToken = accessToken.substring(7);
  let tokenArr = jwtToken.split(".");
  let tokenObj = parseJwt(tokenArr[1]);
  let accessExpiredAt = tokenObj.access_expired_at * 1000;
  return !moment.utc().isAfter(moment(accessExpiredAt).utc());
}

export function isInvited() {
  let userInfo = getUserInfoByJWT();
  return getPlatformStatusByCode(userInfo.status, "invited");
}

export const hasNotifyPermission = async () => {
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  }
  if (Notification.permission === "granted") {
    return true;
  }
  if (Notification.permission !== "denied") {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      return true;
    }
  }
  return false;
};

export const isCurrentWindow = () => {
  return document.visibilityState === "visible";
};

export const notifyMessage = (message: any) => {
  const { msg_type, msg_contents } = message;
  switch (msg_type) {
    case MESSAGE_TYPE_ENUM.TEXT:
      return msg_contents;
    case MESSAGE_TYPE_ENUM.SUDO_SWAP_CARD:
      return "You receive a SudoSwap message!";
    default:
      return "";
  }
};

export function getHref(content: string) {
  if (!content) {
    return "";
  }
  let urlPattern =
    /(https?:\/\/|www\.)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[\(\)~#&\-=?\+\%/\.\w]+)?/g;
  content = content.replace(urlPattern, function (match) {
    var href = match;
    if (match.indexOf("http") == -1) {
      href = "http://" + match; // **加粗样式**
    }
    return '<a target="_blank" href="' + href + '">' + match + "</a>";
  });
  return content;
}

export async function getUserInfoByMap(userId: string, userMap: any[]) {
  if (!userId || userId === "0") return null;
  let userInfo = userMap.find((item: any) => item.user_id === userId);
  if (!userInfo) {
    const loginUserInfo = getUserInfoByJWT();
    if (userId === loginUserInfo.user_id) {
      userInfo = loginUserInfo;
    } else {
      let userRes = await ChatApi.userInfo({
        platform: "user_id",
        user_name: userId,
      });
      userInfo = userRes.data || null;
    }
  }
  return userInfo;
}
