import {
  IonAvatar,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  useIonToast,
} from "@ionic/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./index.scss";
import { useStore } from "../../services/mobx/service";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import {
  getAccessToken,
  getEthAccount,
  getUserAvatar,
  getUserNickName, isLogin,
} from "../../constant/utils";
import { UserInfoInterface } from "../../services/api/apiInterface/chatInterface";
import * as ChatApi from "../../services/api/chat";
import {
  TRANSFER_CONTRACT_ADDRESS,
  USDT_TOKENS_ADDRESS,
  WS_ROOT_URL,
} from "../../constant";
import { CONTRACT_STATUS_ENUM, MESSAGE_TYPE_ENUM } from "../../constant/enum";

const housechanIcon = require("../../assert/svg/housechanIcon.svg");
const walletIcon = require("../../assert/svg/walletIcon.svg");
const whiteTransferIcon = require("../../assert/svg/whiteTransferIcon.svg");
const noSelectNft = require("../../assert/svg/noSelectNft.svg");
const selectedNFT = require("../../assert/svg/selectedNFT.svg");
const nftnoImg = require("../../assert/svg/nftnoImg.svg");
const addTokensIcon = require("../../assert/svg/addTokens.svg");
const stepTwoBackIcon = require("../../assert/svg/stepTwoBackIcon.svg");
const transferStepTwoIcon = require("../../assert/svg/transferStepTwoIcon.svg");
const userIcon = require("../../assert/svg/user.svg");
const closeIcon = require("../../assert/svg/closeIcon.svg");
const payMoneyDownIcon = require("../../assert/svg/payMoneyDownIcon.svg");
const ethInputIcon = require("../../assert/svg/ETH.svg");
const delMoneyIcon = require("../../assert/svg/delMoney.svg");
const radioNoSelectIcon = require("../../assert/svg/radioNoSelectIcon.svg");
const radioSelectedIcon = require("../../assert/svg/radioSelectedIcon.svg");
const TimeExpireType = {
  PERMANENT: "Permanent",
  EXPIRE: "expire",
};

const NFTSwapPage: React.FC = () => {
  const [present] = useIonToast();
  const store = useStore();
  const history = useHistory();
  const { myAssets } = store.NFTSwapStates;

  const [value, setValue] = useState("0");
  const [dealStep, setDealStep] = useState<number>(0);
  const [message, setMessage] = useState("");
  const [recipientProfile, setRecipientProfile] = useState<UserInfoInterface>();
  const [showAddTokenModal, setShowAddTokenModal] = useState(false);
  const [money, setMoney] = useState<number>(0.0);
  const [balance, setBalance] = useState(0);
  const [gender, setGender] = useState("One Day");
  const [timeRadioType, setTimeRadioType] = useState(TimeExpireType.PERMANENT);
  const [ws, setWs] = useState<any>();
  const [roomId, setRoomId] = useState("");

  const selectOptions = {
    cssClass: "my-custom-interface",
  };

  const getMyAssets = async () => {
    store.NFTSwapStates.setShowLoading(true);

    let address = store.authStates.loginUserInfo.eth_wallet_address;
    if (!address) {
      let account = await getEthAccount();
      address = account.address;
    }
    await store.NFTSwapStates.getMyAssets(
      address,
      1,
      50
    );
    store.NFTSwapStates.setShowLoading(false);
  };

  const handleSegmentChange = (e: any) => {
    setValue(e.detail.value);
  };

  const submitNFTSwap = async () => {
    let addresses = [];
    let moneys = [];
    let NFTa: any[] = [];
    let NFTIDs: any[] = [];
    if (selectedNFTLength.length > 0) {
      for (let i = 0; i < selectedNFTLength.length; i++) {
        NFTa[i] = selectedNFTLength[i].asset_contract.address;
        NFTIDs[i] = [Number(selectedNFTLength[i].token_id)];
      }
    }
    //todo weth的tokens
    addresses[0] = USDT_TOKENS_ADDRESS;
    // todo wth需要加18位
    moneys[0] = +money;
    let expiration = Number((Date.now() / 1000).toFixed(0));

    if (timeRadioType === TimeExpireType.PERMANENT) {
      expiration += 86400 * 365 * 10;
    } else {
      if (gender === "One Day") {
        expiration += 86400;
      }
      if (gender === "Three Days") {
        expiration += 86400 * 3;
      }
      if (gender === "One Week") {
        expiration += 86400 * 7;
      }
      if (gender === "One Month") {
        expiration += 86400 * 30;
      }
    }
    if (recipientProfile) {
      let params = {
        recipient_id: recipientProfile.user_id,
        erc20s: addresses,
        amounts: moneys,
        NFTs: NFTa,
        NFTIDs: NFTIDs,
        expiration,
        descriptions: message,
        blockchain_address: TRANSFER_CONTRACT_ADDRESS,
      };
      const createContractsRes = await ChatApi.createNFTContract(params);
      if (createContractsRes.code === 0) {
        // 发送卡片消息
        if (ws) {
          ws.send(
            JSON.stringify({
              to: store.chatStates.roomId,
              msg_type: MESSAGE_TYPE_ENUM.CONTRACT_CREATED,
              msg_contents: {
                tx_id: createContractsRes.data.contract_id,
                receiver_id: recipientProfile.user_id,
                title: message || "God bless you",
                status: CONTRACT_STATUS_ENUM.CREATED,
              },
              from_uid: store.authStates.loginUserInfo.user_id,
            })
          );
        }
      }
    }
  };

  const selectedNFTLength = useMemo(() => {
    return myAssets.filter((item) => item.isSelected === true);
  }, [myAssets]);

  const RenderOpenDeals = useCallback(() => {
    return <div>open deals</div>;
  }, [value]);

  const RenderDealsHistory = useCallback(() => {
    return <div>Deals History</div>;
  }, [value]);

  const RenderStepOneContent = useCallback(() => {
    return (
      <div className="nft-swap-content-box">
        {myAssets.length > 0 &&
          myAssets.map((item) => {
            return (
              <div className="nft-item-flex-box" key={item.id}>
                <div
                  className="nft-item-box"
                  onClick={() => {
                    if (dealStep) {
                      if (!item.isSelected && selectedNFTLength.length >= 3) {
                        present("一次最多选择4个NFT", 1000);
                        return false;
                      }
                      store.NFTSwapStates.selectedOneNFT(item);
                    }
                  }}
                >
                  <img
                    className="nft-item-img"
                    src={item.image_preview_url || nftnoImg}
                    alt=""
                  />
                  <div className="nft-item-text-box">
                    <div>{item.collection.name || ""}</div>
                    <div>{item.name}</div>
                    {/*{item.last_sale ? (*/}
                    {/*  <div>*/}
                    {/*    <img src={EthLogoIcon} alt="" />*/}
                    {/*    <div> {}</div>*/}
                    {/*  </div>*/}
                    {/*) : null}*/}
                    {dealStep === 1 ? (
                      <div className="nft-item-select-box">
                        <img
                          src={item.isSelected ? selectedNFT : noSelectNft}
                          alt=""
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }, [myAssets, dealStep]);

  const RenderHeaderRightBtns = useCallback(() => {
    const selectedNFTLength = myAssets.filter(
      (item) => item.isSelected === true
    );
    if (dealStep === 0) {
      return (
        <div className="nft-swap-button">
          <IonButton
            className="start-swap-button"
            onClick={() => {
              setDealStep(1);
            }}
          >
            <img src={whiteTransferIcon} alt="" />
            Make a trade
          </IonButton>
        </div>
      );
    }
    if (dealStep === 1) {
      return (
        <div className="nft-swap-button">
          <IonButton
            className="cancel-swap-button"
            onClick={() => {
              setDealStep(0);
              store.NFTSwapStates.cancelSelectNFT();
            }}
          >
            Cancel
          </IonButton>
          <IonButton
            className="start-swap-button"
            disabled={selectedNFTLength.length <= 0}
            onClick={() => {
              setDealStep(2);
            }}
          >
            <img src={whiteTransferIcon} alt="" />
            Next
          </IonButton>
        </div>
      );
    }
    if (dealStep === 2) {
      return (
        <div className="nft-swap-button">
          <IonButton
            className="cancel-swap-button"
            onClick={() => {
              setDealStep(0);
              store.NFTSwapStates.cancelSelectNFT();
            }}
          >
            Cancel
          </IonButton>
          <IonButton
            disabled={!recipientProfile}
            className="start-swap-button"
            onClick={submitNFTSwap}
          >
            Send to chat
          </IonButton>
        </div>
      );
    }
    return null;
  }, [
    dealStep,
    selectedNFTLength,
    recipientProfile,
    timeRadioType,
    money,
    gender,
  ]);

  const RenderLeftHeader = () => {
    if ([0, 1].includes(dealStep)) {
      return (
        <div className="nft-swap-tabs-box">
          <IonSegment
            className="dashboard-content-segment"
            value={value}
            onIonChange={(e) => {
              handleSegmentChange(e);
            }}
          >
            <IonSegmentButton value="0">
              <IonLabel>My NFTs</IonLabel>
            </IonSegmentButton>
            {/*<IonSegmentButton value="1">{t("Open Deals")}</IonSegmentButton>*/}
            {/*<IonSegmentButton value="2">*/}
            {/*  {t("Deals History")}*/}
            {/*</IonSegmentButton>*/}
          </IonSegment>
        </div>
      );
    }
    if (dealStep === 2) {
      return (
        <div className="nft-swap-step-two-left-header">
          <div>
            <img src={stepTwoBackIcon} alt="" /> Back
          </div>
          <div>
            <div>Make a Trade</div>
            <div className="nft-swap-content-step-title">
              <span style={{ fontWeight: "bold" }}>Step 2 of 2 :</span>{" "}
              Compelete the swap proposal and send to recipient.
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const RecipientProfileCard = () => {
    if (recipientProfile) {
      return (
        <div className="recipient-profile-card">
          {!store.chatStates.roomInfo.is_1v1 ? (
            <div
              className="recipient-back-icon"
              onClick={() => {
                setRecipientProfile(undefined);
              }}
            >
              <img src={stepTwoBackIcon} alt="" /> Back
            </div>
          ) : null}
          <img
            className="recipient-profile-card-avatar"
            src={
              getUserAvatar(recipientProfile, store.authStates.platform) ||
              userIcon
            }
            alt=""
          />
          <div className="recipient-profile-card-username">
            {getUserNickName(recipientProfile, store.authStates.platform)}
          </div>
          <div className="recipient-profile-card-address">
            <img src={walletIcon} alt="" />
            <div>{recipientProfile.eth_wallet_address}</div>
          </div>
        </div>
      );
    }
    return null;
  };

  const RenderRecipientList = () => {
    return (
      <div className="step-two-recipient-list-box">
        <div>Please select recipient</div>
        <IonList className="step-two-recipient-list">
          {store.chatStates.roomMembers.map((contact) => {
            return (
              <IonItem
                className="step-two-recipient-list-item"
                onClick={async () => {
                  setRecipientProfile(contact);
                }}
              >
                <IonAvatar slot="start" className="recipient-list-item-avatar">
                  <img
                    src={
                      getUserAvatar(contact, store.authStates.platform) ||
                      userIcon
                    }
                    alt=""
                  />
                </IonAvatar>
                <IonLabel className="recipient-list-item-username">
                  {getUserNickName(contact, store.authStates.platform)}
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </div>
    );
  };

  const init = async () => {
    // 获取全部chat
    if (!isLogin()) {
      history.replace(
        `/chat/auth?platform=${store.authStates.platform}&fromPage=${store.authStates.fromPage}`
      );
      return;
    }

    if (store.chatStates.roomInfo.is_1v1) {
      setRecipientProfile(store.chatStates.roomMembers[0]);
    }
    if (!ws) {
      const newWs = new WebSocket(
        `${WS_ROOT_URL}/ws?token=${getAccessToken()}`
      );
      setWs(newWs);
    }
    const urlSearch = new URLSearchParams(window.location.search);
    let roomId = urlSearch.get("roomId");
    if (roomId) setRoomId(roomId);
    await getMyAssets();
  };

  useEffect(() => {
    console.log(store.chatStates, "store.chatStates");
    let screenWidth = document.body.clientWidth;
    // !/mobile/i.test(navigator.userAgent)
    if (screenWidth <= 600) {
      history.replace("/chat/home/chats");
    }

    init().then();
  }, []);

  return (
    <div className="nft-swap-page">
      <div className="nft-swap-header">
        <div className="nft-swap-header-item-logo">
          <img className="house-logo" src={housechanIcon} alt="" />
          <div>SwapChat</div>
        </div>
        <div className="nft-swap-header-item-address">
          <img src={walletIcon} alt="" />
          <div>{store.authStates.loginUserInfo.eth_wallet_address}</div>
        </div>
      </div>
      <div className="nft-swap-content">
        <div className="nft-swap-tab-header">
          <RenderLeftHeader />
          <RenderHeaderRightBtns />
        </div>
        {dealStep === 1 && (
          <div className="nft-swap-content-step-title nft-swap-content-step-one-title">
            <span style={{ fontWeight: "bold" }}>Step 1 of 2:</span> You can
            select one or more NFTs that you want to swapping with others.
          </div>
        )}
        {value === "0" ? (
          [0, 1].includes(dealStep) ? (
            <RenderStepOneContent />
          ) : dealStep === 2 ? (
            <div className="nft-swap-step-two-content-box">
              <div className="step-two-left-card">
                <div className="step-two-left-card-title">
                  <div>Swap with</div>
                  <div>
                    <IonButton
                      onClick={async () => {
                        const account = await getEthAccount();
                        if (account.balance) {
                          setBalance(account.balance);
                          setShowAddTokenModal(true);
                        }
                      }}
                      className="add-tokens-btn"
                    >
                      <img src={addTokensIcon} alt="" />
                      Add Tokens
                    </IonButton>
                  </div>
                </div>
                <div
                  className="step-two-left-card-nft-box"
                  style={{
                    justifyContent:
                      selectedNFTLength.length > 2 ? "space-between" : "",
                  }}
                >
                  {selectedNFTLength.length > 0 &&
                    selectedNFTLength.map((item: any) => {
                      return (
                        <div
                          className="step-two-nft-item"
                          key={item.id}
                          style={{
                            marginRight:
                              selectedNFTLength.length > 2 ? "0" : "15px",
                          }}
                        >
                          <img
                            className="step-two-nft-item-img"
                            src={item.image_preview_url || nftnoImg}
                            alt=""
                          />
                          <div className="step-two-nft-item-text-box">
                            <div>CryptoPunks</div>
                            <div>{item.name}</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {money > 0 ? (
                  <div className="step-two-left-card-money-box">
                    <img src={ethInputIcon} alt="" />
                    <div className="account-balance">{money} ETH</div>
                    <img
                      onClick={() => {
                        setMoney(0);
                      }}
                      src={delMoneyIcon}
                      alt=""
                    />
                  </div>
                ) : null}
                <div className="step-two-left-card-time-box">
                  <div>
                    <img
                      onClick={() => {
                        setTimeRadioType(TimeExpireType.PERMANENT);
                        setGender("One Day");
                      }}
                      src={
                        timeRadioType === TimeExpireType.PERMANENT
                          ? radioSelectedIcon
                          : radioNoSelectIcon
                      }
                      alt=""
                    />
                    <div>Permanent</div>
                  </div>
                  <div>
                    <img
                      onClick={() => {
                        setTimeRadioType(TimeExpireType.EXPIRE);
                      }}
                      src={
                        timeRadioType === TimeExpireType.EXPIRE
                          ? radioSelectedIcon
                          : radioNoSelectIcon
                      }
                      alt=""
                    />
                    <div>Expire in</div>
                    <div>
                      <IonSelect
                        interface="popover"
                        interfaceOptions={selectOptions}
                        disabled={timeRadioType === TimeExpireType.PERMANENT}
                        value={gender}
                        placeholder="Select One"
                        onIonChange={(e) => setGender(e.detail.value)}
                      >
                        <IonSelectOption value="One Day">
                          One Day
                        </IonSelectOption>
                        <IonSelectOption value="Three Days">
                          Three Days
                        </IonSelectOption>
                        <IonSelectOption value="One Week">
                          One Week
                        </IonSelectOption>
                        <IonSelectOption value="One Month">
                          One Month
                        </IonSelectOption>
                      </IonSelect>
                    </div>
                  </div>
                </div>
                <div className="step-two-left-card-message-box">
                  <div className="step-two-left-card-message-title">
                    Message
                  </div>
                  <div className="step-two-left-card-message-input">
                    <IonTextarea
                      rows={3}
                      className="textarea-value"
                      value={message}
                      onIonChange={(e) => setMessage(e.detail.value!)}
                      placeholder="Optional message..."
                    />
                  </div>
                </div>
              </div>
              <div className="step-two-middle-card">
                <div className="step-two-transfer-box">
                  <img src={transferStepTwoIcon} alt="" />
                </div>
              </div>
              <div className="step-two-right-card">
                <div className="step-two-left-card-title">
                  <div>Recipient</div>
                </div>
                {recipientProfile ? (
                  <RecipientProfileCard />
                ) : (
                  <RenderRecipientList />
                )}
              </div>
            </div>
          ) : null
        ) : null}
        {value === "1" && <RenderOpenDeals />}
        {value === "2" && <RenderDealsHistory />}
      </div>
      <IonModal
        isOpen={showAddTokenModal}
        //@ts-ignore
        cssClass="nft-swap-add-token-modal"
        onDidDismiss={() => {
          setShowAddTokenModal(false);
        }}
      >
        <div className="add-token-transfer-modal">
          <div className="add-token-header">
            <div>Add token to the trade</div>
            <div
              onClick={() => {
                setShowAddTokenModal(false);
                setMoney(0);
              }}
            >
              <img src={closeIcon} alt="" />
            </div>
          </div>
          <div className="add-token-content">
            <div className="add-token-input-box">
              <div className="add-token-input-title">
                <div>Asset</div>
                <div>We currently only support ETH</div>
              </div>
              <div className="add-token-input">
                <img src={ethInputIcon} alt="" />
                <div className="account-balance">
                  {balance.toFixed(4)} ETH available
                </div>
                <img src={payMoneyDownIcon} alt="" />
              </div>
            </div>
            <div className="add-token-input-box">
              <div className="add-token-input-title">
                <div>Amount</div>
              </div>
              <div className="add-token-input">
                <div className="add-token-input-money-box">
                  <IonInput
                    className="add-token-money-input"
                    value={money}
                    type="number"
                    placeholder="0.00"
                    onIonChange={(e) => {
                      let value = e.detail.value!;
                      setMoney(+value);
                    }}
                  />
                  <div>ETH</div>
                </div>
              </div>
            </div>
          </div>
          <div className="add-token-footer">
            <IonButton
              onClick={() => {
                setShowAddTokenModal(false);
              }}
              className="submit-add-token-btn"
            >
              Add
            </IonButton>
          </div>
        </div>
      </IonModal>
      <IonLoading
        isOpen={store.NFTSwapStates.showLoading}
        onDidDismiss={() => store.chatStates.setShowLoading(false)}
        message={"Loading"}
      />
    </div>
  );
};

export default observer(NFTSwapPage);
