import React, {useCallback, useEffect, useMemo, useState} from "react";

import "./index.scss";
import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonContent, IonHeader,
  IonIcon, IonInfiniteScroll, IonInfiniteScrollContent,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  useIonToast,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../services/mobx/service";
import moment from "moment";
import ChatPageSkeleton from "../../../components/Skeletons/ChatPageSkeleton/ChatPageSkeleton";
import { notificationsOutline } from "ionicons/icons";
import HomeNotifyContent from "./components/HomeNotifyContent";
import {logEvent} from "../../../utils/analytics";
import {isInvited, isLogin} from "../../../constant/utils";

const userIcon = require("../../../assert/svg/user.svg");
const spacesIcon = require("../../../assert/img/spacesIcon.png");

/**
 *  粉丝和主播聊天
 */
const Chats: React.FC = observer(() => {
  const history = useHistory();
  const [present] = useIonToast();
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState(false);
  const store = useStore();
  const pageSize = 20

  const RenderChatsItem = useCallback(
    (props) => {
      const { room } = props;
      let isTwitterSpace = room.is_twitter_space
      if (!room.members) return null;
      return (
        <IonItemSliding>
          <IonItem
            className="chat-list-item"
            onClick={() => {
              let pathName = `/chat/chatWebPage?roomId=${room.room_id}`;
              history.replace(pathName);
              return;
            }}
          >
            <IonAvatar
              slot="start"
              className={
                room.is_1v1
                  ? "message-list-avatar"
                  : "message-list-avatar group-room-avatar"
              }
            >
              <img
                src={isTwitterSpace ? spacesIcon : room.room_avatar ? room.room_avatar : userIcon}
                alt=""
              />
            </IonAvatar>
            <IonLabel className="message-body center-label">
              <p className="up-text">{room.room_name || "Unnamed"}</p>
              <p
                className={
                  room.un_read_count > 0
                    ? "down-text un-read-text"
                    : "down-text"
                }
              >
                {room.last_message || ""}
              </p>
            </IonLabel>
            <IonLabel slot="end" className="message-body">
              {room.last_message_time > 0 && (
                <p className="date-text">
                  {moment.utc(room.last_message_time).local().format("MM/DD")}
                </p>
              )}
              {room.un_read_count > 0 ? (
                <div className="un-read-count">{room.un_read_count || ""}</div>
              ) : null}
            </IonLabel>
          </IonItem>
          <IonItemOptions side="end" className="chat-list-item-delete-options">
            <IonItemOption onClick={() => {}}>Delete</IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      );
    },
    [store.homeStates.chats]
  );

  const isPC = useMemo(() => {
    let screenWidth = document.body.clientWidth;
    // !/mobile/i.test(navigator.userAgent)
    return screenWidth > 600;
  }, []);

  const loadData = async (ev: any) => {
    let currentPage = (store.homeStates.chats.length / pageSize).toFixed(0)
    let item = +currentPage + 1;
    await getChats(item)
    ev.target.complete();
  };

  const getChats = async (page: number) => {
    await store.homeStates.getChats(store.authStates.platform, page, pageSize);
    if (store.homeStates.currentSearchChatsLength <  pageSize) {
      setIsInfiniteDisabled(true)
    }
  }


  useEffect(() => {
    if (isPC) {
      // history.replace('/chat/chatpc');
    }
    const init = async () => {
      if (!isLogin()) {
        history.replace(
          `/chat/auth?platform=${store.authStates.platform}&fromPage=${store.authStates.fromPage}`
        );
        return;
      }
      if (!isInvited()) {
        console.log('从聊天列表跳转到认证code页面')
        history.replace("/chat/verifyCodePage");
        return;
      }

      logEvent('chats-page', 'visit')

      setIsInfiniteDisabled(false)
      await getChats(1)
      store.setWs()
      store.homeStates.setShowLoading(true);
      await store.authStates.getMyInviteCodes();

      store.chatStates.setShowLoading(false);
    };
    init();
  }, []);

  return (
    <>
      {store.chatStates.showLoading ? (
        <ChatPageSkeleton />
      ) : (
        <IonPage className="house-super-chats-page">
          <IonHeader className="house-super-chats-page-header">
            <p>Chats</p>
            <IonButton
              className="notify-icon"
              onClick={() => {
                store.chatStates.setShowNotifyPopover(true);
                store.homeStates.setNotificationMessages([])
              }}
            >
              <IonIcon
                style={{ color: "#000", fontSize: "24px" }}
                slot="icon-only"
                icon={notificationsOutline}
              />
            </IonButton>
            {store.homeStates.unReadNotificationCount > 0 && (
              <div className="un-read-notification-message-count">
                {store.homeStates.unReadNotificationCount}
              </div>
            )}
          </IonHeader>
          <IonContent className="house-super-chats-page-content">
            <IonList>
              {store.homeStates.chats.length > 0 &&
                store.homeStates.chats.map((room: any, index) => (
                  <RenderChatsItem key={index} room={room} />
                ))}
            </IonList>
            <IonInfiniteScroll
              onIonInfinite={loadData}
              threshold="51px"
              disabled={isInfiniteDisabled}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="Loading more data"
              />
            </IonInfiniteScroll>
          </IonContent>
          <IonModal
            //@ts-ignore
            cssClass="chats-notify-popover"
            isOpen={store.chatStates.showNotifyPopover}
            onDidDismiss={() => store.chatStates.setShowNotifyPopover(false)}
          >
            <HomeNotifyContent />
          </IonModal>
          <IonAlert
            isOpen={store.chatStates.showErrorAlert}
            onDidDismiss={() => {
              store.chatStates.setShowErrorAlert(false);
              store.chatStates.setErrorMessage("");
            }}
            header={"Error"}
            message={store.chatStates.errorMessage}
            buttons={["Dismiss"]}
          />
        </IonPage>
      )}
    </>
  );
});
export default Chats;
