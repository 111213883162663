import React, { useState } from "react";

import "./SelectUser.scss";
import { useStore } from "src/services/mobx/service";
import { observer } from "mobx-react-lite";
import { getUserAvatar, getUserNickName } from "../../../../../constant/utils";
import { UserInfoInterface } from "../../../../../services/api/apiInterface/chatInterface";
import { IonInput } from "@ionic/react";

const selectUserDownIcon = require("../../../../../assert/svg/selectUserDownIcon.svg");
const userSelectedIconTrue = require("../../../../../assert/svg/userSelectedIcon.svg");
const userSelectedIconFalse = require("../../../../../assert/svg/userUnSelectedIcon.svg");
const userIcon = require("../../../../../assert/svg/user.svg");
const closeIcon = require("../../../../../assert/svg/closeIcon.svg");

interface IAppProps {
  users?: UserInfoInterface[];
  style?: React.CSSProperties;
}

const SelectUser: React.FC<IAppProps> = observer((props) => {
  const store = useStore();
  const { users = store.homeStates.userFollowers, style } = props;
  const [sendToNumber, setSendToNumber] = useState("");
  const [scopedUsers, setScopedUsers] = useState<UserInfoInterface[]>(users);
  // const delOneUserPushMessage = (data: UserInfoInterface) => {
  //   let oriData = selectedUsers;
  //   let newData: UserInfoInterface[] = [];
  //   oriData.forEach((item) => {
  //     if (data.user_id !== item.user_id) {
  //       newData.push(item);
  //     }
  //   });
  //   setSelectedUsers(newData);
  // };
  //
  // const addOneUserPushMessage = (data: UserInfoInterface) => {
  //   setSelectedUsers([...selectedUsers, data]);
  // };

  const handleUserItemClick = (user: UserInfoInterface) => {
    console.log(user);
    const isSelected = store.chatStates.pushMessageSelectedUsers.find(
      (item) => item.user_id === user.user_id
    );
    if (isSelected) {
      // delOneUserPushMessage(user);
      store.chatStates.delOneUserPushMessage(user);
    } else {
      // addOneUserPushMessage(user);
      store.chatStates.addOneUserPushMessage(user);
    }
    if (sendToNumber) {
      console.log("准备清空数据");
      setSendToNumber("");
      setScopedUsers(store.homeStates.contacts);
    }
  };

  // 有瑕疵
  // const RenderUserItemEle = useCallback(
  //   (props) => {
  //     console.log("RenderUserItemEle is render");
  //     const { user } = props;
  //     const isSelected = selectedUsers.find(
  //       (item) => item.user_id === user.user_id
  //     );
  //
  //     const userAvatar = getUserAvatar(user, store.authStates.platform);
  //     return (
  //       <div
  //         className="select-user-item"
  //         onClick={() => {
  //           handleUserItemClick(user);
  //         }}
  //       >
  //         <img
  //           src={isSelected ? userSelectedIconTrue : userSelectedIconFalse}
  //           alt=""
  //         />
  //         <img
  //           onError={({ currentTarget }) => {
  //             currentTarget.onerror = null;
  //             currentTarget.src = userIcon;
  //           }}
  //           src={userAvatar || userIcon}
  //           alt=""
  //         />
  //         <div>{getUserNickName(user, store.authStates.platform)}</div>
  //       </div>
  //     );
  //   },
  //   [selectedUsers, scopedUsers]
  // );

  return (
    <div className="select-push-user" style={style}>
      {/*  输入框，选中的人*/}
      <div className="select-user-up-box">
        {/*<div className="selected-users">*/}
        {store.chatStates.pushMessageSelectedUsers.length > 0 &&
          store.chatStates.pushMessageSelectedUsers.map((user, index) => {
            return (
              <div key={index} className="push-message-user-item">
                <img
                  className="select-user-avatar-pre"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = userIcon;
                  }}
                  src={
                    getUserAvatar(user, store.authStates.platform) || userIcon
                  }
                  alt=""
                />
                <div className="select-user-name">
                  {getUserNickName(user, store.authStates.platform)}
                </div>
                <img
                  src={closeIcon}
                  className="select-user-del-icon"
                  onClick={() => {
                    store.chatStates.delOneUserPushMessage(user);
                  }}
                  alt=""
                />
              </div>
            );
          })}
        {/*</div>*/}
        <div className="select-user-input-box">
          <IonInput
            className="select-user-input"
            value={sendToNumber}
            onIonChange={(e) => {
              let phone = e.detail.value!;
              setSendToNumber(phone);
              let itemContacts = store.homeStates.contacts.filter((user) => {
                let username = getUserNickName(user, store.authStates.platform);
                if (username !== "Unnamed" && username.indexOf(phone) !== -1) {
                  return true;
                } else {
                  return false;
                }
              });
              console.log(itemContacts, "itemContacts");
              setScopedUsers(itemContacts);
            }}
            placeholder="Select users"
            onIonFocus={() => {
              // setSearching(true);
              store.chatStates.setSearching(true);
            }}
            onIonBlur={() => {
              // setSendToNumber("");
              // setScopedUsers(store.homeStates.contacts);
              // setSearching(false);
            }}
          />
          <img src={selectUserDownIcon} alt="" />
        </div>
      </div>
      {/* 列表*/}
      {store.chatStates.pushMessageUserSearching && (
        <div className="select-user-down-box">
          <div className="user-list-box">
            {scopedUsers.length > 0 &&
              scopedUsers.map((user, index) => {
                const isSelected =
                  store.chatStates.pushMessageSelectedUsers.find(
                    (item) => item.user_id === user.user_id
                  );
                return (
                  <div
                    key={index}
                    className="select-user-item"
                    onClick={() => {
                      handleUserItemClick(user);
                    }}
                  >
                    <img
                      src={
                        isSelected
                          ? userSelectedIconTrue
                          : userSelectedIconFalse
                      }
                      alt=""
                    />
                    <img
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = userIcon;
                      }}
                      src={
                        getUserAvatar(user, store.authStates.platform) ||
                        userIcon
                      }
                      alt=""
                    />
                    <div>
                      {getUserNickName(user, store.authStates.platform)}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
});
export default SelectUser;
